const HighLightsStyle = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '11rem',
    paddingTop: '0.9rem',
    paddingRight: '0.9rem',
    paddingLeft: '0.9rem',
    paddingBottom: '0.1rem',
  },
  topRight: {
    textAlign: 'right',
  },
  topRightType2: {
    textAlign: 'right',
    cursor: 'pointer',
    padding: '0.1rem',
    // marginLeft:'8.5rem',
    borderRadius: '0.5rem',
    '&:hover': {
      //backgroundColor: "#FBD8D3",
      boxShadow: 'none',
      background: 'rgba(249, 126, 109, 0.3)',
    },
  },
  topLeft: {
    textAlign: 'left',
  },
  title: {
    color: '#757575',
    fontWeight: 500,
    fontSize: '0.7rem',
    textAlign: 'right',
  },
  period: {
    color: '#807a7a',
    fontWeight: 500,
    fontSize: '0.6rem',
    textAlign: 'right',
  },
  title1: {
    fontSize: '1.2rem',
    fontWeight: 900,
    marginTop: '-0.2rem',
  },
  title2: {
    fontSize: '1.4rem',
    fontWeight: 900,
    marginTop: '0.2rem',
  },
  subTitle: {
    fontSize: '0.6rem',
    color: '#757575',
    fontWeight: 300,
  },
  imgBox: {
    textAlign: 'center',
    borderRadius: '0.3rem',
    marginTop: '1rem',
    height: '2rem',
    width: '3rem',
    //border: `2px solid #32b504`,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: '#FFFFFF',
    width: '2rem',
    height: '2rem',
  },
  secondRow: {
    //paddingRight: "0.3rem",
    //marginTop: "0.2rem"
  },
  thirdRow: {
    // paddingRight: "0.5rem",
    //marginTop: "0.2rem"
  },
  title3: {
    textAlign: 'left',
    fontSize: '0.7rem',
    color: '#757575',
    fontWeight: 300,
  },
  title3Nw: {
    textAlign: 'Right',
    fontSize: '0.7rem',
    color: '#757575',
    fontWeight: 300,
  },
  title3new: {
    textAlign: 'left',
    fontSize: '0.9rem',
    color: '#757575',
    fontWeight: 300,
  },
  title3up: {
    textAlign: 'left',
    fontSize: '0.6rem',
    color: '#757575',
    fontWeight: 500,
  },
  title3meter: {
    textAlign: 'left',
    fontSize: '0.8rem',
    color: '#757575',
    fontWeight: 400,
  },
  title4meter: {
    textAlign: 'right',
    fontSize: '0.9rem',
    color: '#757575',
    fontWeight: 400,
    marginBottom: '-0.3rem',
  },
  title4: {
    textAlign: 'right',
    fontSize: '1rem',
    color: '#757575',
    fontWeight: 500,
    marginBottom: '-0.3rem',
  },
  title5: {
    textAlign: 'center',
    fontSize: '1.1rem',
    color: '#757575',
    fontWeight: 500,
    marginBottom: '-0.3rem',
  },
  title6: {
    textAlign: 'center',
    fontSize: '0.6rem',
    color: '#757575',
    fontWeight: 300,
  },
  titleMainBox: {
    // padding: "0.2rem",
    cursor: 'pointer',
    padding: '0.2rem',
    // backgroundColor: "#F3F3F3",
    borderRadius: '0.5rem',
    '&:hover': {
      //backgroundColor: "#FBD8D3",
      boxShadow: 'none',
      background: 'rgba(249, 126, 109, 0.3)',
    },
  },
  titleMainBox2: {
    // padding: "0.2rem",
    cursor: 'pointer',
    padding: '0.1rem',
    //backgroundColor: "#F3F3F3",
    borderRadius: '0.5rem',
    '&:hover': {
      //backgroundColor: "#FBD8D3",
      boxShadow: 'none',
      background: 'rgba(249, 126, 109, 0.3)',
    },
  },
  box: {
    cursor: 'pointer',
    padding: '0.2rem',
    backgroundColor: '#F3F3F3',
    borderRadius: '0.5rem',
    '&:hover': {
      backgroundColor: '#FBD8D3',
      boxShadow: 'none',
    },
  },
  titleBox: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'right',
    flexDirection: 'column',
  },
  veriticalLine: {
    height: '1rem',
    borderLeft: '1px solid #757575',
  },
  star: {
    color: '#ffa901',
    width: '1.5rem',
    height: '1.5rem',
    marginLeft: '0.5rem',
  },
  noStar: {
    color: '#E3DEDE',
    width: '1.5rem',
    height: '1.5rem',
    marginLeft: '0.5rem',
  },
  starContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'right',
    flexDirection: 'row',
    marginTop: '1rem',
  },
  titleimg: {
    width: '4rem',
    height: '4.5rem',
    color: '#757575',
  },
  titlecard5: {
    textAlign: 'center',
    fontSize: '0.6rem',
    color: '#757575',
    fontWeight: 300,
  },
  desView: {
    height: '4rem',
  },
  desView2: {
    height: '4.7rem',
  },
  desView3: {
    height: '6.2rem',
  },
};

export default HighLightsStyle;
