import { GoogleMap, useLoadScript } from "@react-google-maps/api";
import React, { useContext, useEffect } from "react";
import CustomDirectionRenderer from "./CustomDirectionRenderer";
import CustomersLocationManager from "./CustomersLocationManager";
import { GoogleMapsAPI } from "../ClientConfig";
import { RouteReportContext } from "views/Reports/TechnicianRouteReport";

const googleMapsApiKey = GoogleMapsAPI;
const libraries = ["places"];

const ReportMap = () => {
  const { travelRoutes: journeys, center, setIsGoogleMapLoaded, googleMapRef, routeMarker, routeMarkerHandlers } = useContext(RouteReportContext);
  const { isGpsFocused } = routeMarker; 
  const { handleGpsFocus } = routeMarkerHandlers;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
  });

  useEffect(() => {
    setIsGoogleMapLoaded(isLoaded);
  }, [isLoaded]);

  if (loadError)
    return (
      <div style={{ color: "red" }}>
        There is a Problem with your google map!
      </div>
    );

  return isLoaded ? (
    <div
      style={{
        position: "fixed",
        right: 0,
        top: "0",
        height: "100vh",
        width: "100%",
      }}
    >
      <GoogleMap
        onZoomChanged={() => {
          if (isGpsFocused) handleGpsFocus();
        }}
        onLoad={mapRef => googleMapRef.current = mapRef}
        zoom={13}
        center={center}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          streetViewControl:false,
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          minZoom: 5,
        }}
      >
        {journeys.map((journey, index) => (
          <DirectionGenerator
            key={index}
            journeyObject={journey}
          />
        ))}
        <CustomersLocationManager />
      </GoogleMap>
      {/* <MapIconViewer /> */}
    </div>
  ) : (
    <div style={{ color: "red", backgroundColor: "white" }}>Loading...</div>
  );
};

const DirectionGenerator = ({ journeyObject }) => {
  const {
    isSelected,
    routeColor,
    journey,
    id,
    name,
    type,
  } = journeyObject;

  const { origin, destination, waypoints } = journey;
  const path = waypoints.map(waypoint => waypoint.location);
  return isSelected ? (
    <CustomDirectionRenderer
      srId={id}
      path={path}
      origin={origin}
      destination={destination}
      routeColor={routeColor}
      srType={type}
      srName={name}
    />
  ) : null;
};

export default ReportMap;
