export const minutesToDaysHoursMinutes = (minutes) => {
    /**
     * Converts minutes to days , hrs and minutes
     * If a unit has no whole value , then the value is displayed with a smaller unit
     */
    if (minutes === '-') return minutes;
    if (minutes <= 60) return `${minutes} min`;

  
    const days = Math.floor(minutes / 1440);
    const hours = Math.floor((minutes % 1440) / 60);
    const remainingMinutes = minutes % 60;
  
    return days > 0 
      ? `${days} days ${hours} hrs ${Math.round(remainingMinutes)} min` 
      : `${hours} hrs ${Math.round(remainingMinutes)} min`;
  };
  
  export const minutesToHoursMinutes = (minutes) => {
    /**
     * Converts minutes to hrs and minutes
     * If a unit has no whole value , then the value is displayed with a smaller unit
     */
    if (minutes === '-') return minutes;
    if (minutes <= 60) return `${minutes} min`;

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours} hrs ${Math.round(remainingMinutes)} min`;
  };


  
  