import { Box } from '@material-ui/core'
import React from 'react'
import Journeys from './Journeys'
import Header from './Header'

const Sidebar = () => {
  return (
    <Box zIndex={4} display="flex" flexDirection="column" style={{gap:"10px"}} position="fixed" top="15px" left="15px" bottom="15px" width="300px">
        <Header />
        <Journeys />
    </Box>
  )
}

export default Sidebar
