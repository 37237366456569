/* eslint-disable no-constant-condition */
import React, { useState, useEffect, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography } from '@material-ui/core';
import UrlProperties from 'Util/Property/UrlProperties';
import LoadingOverlay from 'react-loading-overlay';
import MaterialTable from 'material-table';
import Lottie from 'react-lottie';
import animationData from 'assets/onsiteAnimation.json';
import _ from 'lodash';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import reportsProperties from 'Util/Property/OpeReportProperty';
import { highlight5Properties } from 'Util/Property/HighlightProperties';
import { getCustomerForm } from 'Util/Property/CustomerPorerties';
import ViewMore3 from 'components/Highlights/Viewmore/HighlightsType5ViewMore/Viewmore3.js';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import {minutesToDaysHoursMinutes , minutesToHoursMinutes} from '../../../../Util/Converters/TimeConverters'

export default function ViewMore2(props) {
  const {
    tableData,
    open,
    fileUploadClose,
    tileBody,
    isDragReject,
    title,
    subTitle,
  } = props;
  const [file, setFile] = React.useState([]);

  const [error, setError] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [subTableData = [], setSubTableData] = useState();
  const [openSecondPreview, setOpenSecondPreview] = useState(false);
  const [newFile, setNewFile] = React.useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openFileUpload3, setOpenFileUpload3] = useState(false);
  const [tableCols = [], setTableCols] = useState([]);
  const [tableSubTitle, setTableSubTitle] = useState();

  const baseUrl = UrlProperties.baseUrl.split(':8089')[0];

  const handleClose = () => {
    fileUploadClose();
    //setImageApi(false);
  };
  const fileUploadClose2 = () => {
    setOpenFileUpload3(false);
  };

  const rowRender = (rowData, key) => {
    if (key === 'age') {
      return (
        <div
          style={{
            // padding: '0.5rem',
            background:
              rowData['kpi'].value != null && rowData['age'].value != null
                ? rowData['kpi'].value > rowData['age'].value
                  ? '#92D050'
                  : rowData['kpi'].value < rowData['age'].value
                  ? '#FF3300'
                  : rowData['kpi'].value === rowData['age'].value
                  ? '#FFC000'
                  : null
                : null,
          }}
        >
          {minutesToDaysHoursMinutes(rowData[key].value)}
        </div>
      );
    }

    return (
      <div
        style={{
          padding: '0.5rem',
        }}
      >
        {key === 'province'
          ? getProvince(rowData[key].value)
          : rowData[key].value}
      </div>
    );
  };

  useEffect(() => {
    const colsArr = [];
    if (tableData.length !== 0) {
      Object.entries(tableData[0]).forEach(([key, value]) => {
        const _val = reportsProperties.reqFieldsMap[key];
        if (typeof _val !== 'undefined') {
          return colsArr.push({
            id: tableData[0][key].id,
            title: reportsProperties.reqFieldsMap[key]['table'],
            field: key,
            headerStyle: {
              backgroundColor: '#e6e4df',
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
              margin: '0',
              padding: '0',
            },
            render: (rowData) => {
              return rowRender(rowData, key);
            },
          });
        }
      });
    }

    colsArr.sort(function(a, b) {
      return a.id - b.id;
    });
    setTableCols(colsArr);
  }, []);

  useEffect(() => {}, [file]);

  const getProvince = (key) => {
    const obj = getCustomerForm().province.inputProps.options;
    const index = obj.findIndex((el) => el.value === key);
    if (obj[index] !== undefined) {
      return obj[index].displayValue;
    }
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '140px',
    width: '400px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#B22900',
    borderStyle: 'dashed',
    backgroundColor: '#ffcec7',
    color: '#f73123',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    []
  );

  const getKeyByValue = (obj, value) => {
    const key = Object.keys(obj).find((key) => obj[key].req === value);
    return key;
  };

  const assignTableData = (data) => {
    const tableArr = [];
    var num = 1;
    const fieldKeys = reportsProperties.reqFieldsMap;

    data.forEach((record) => {
      var obj = {};
      Object.assign(obj, {
        num: {
          id: '1',
          value: num,
        },
      });
      var keys = Object.keys(record);
      const tableFields = highlight5Properties.tableFields.table3;

      keys.forEach((field) => {
        const key = getKeyByValue(fieldKeys, field);
        if (key !== undefined) {
          const fieldObj = tableFields.find((ob) => ob.field === key);
          if (fieldObj != null) {
            obj[key] = {
              id: fieldObj.id,
              value: record[field] == null ? '-' : record[field],
            };
          }
        }
      });

      tableArr.push(obj);
      num++;
    });
    return tableArr;
  };

  return (
    <div>
      {openFileUpload3 ? (
        <ViewMore3
          open={openFileUpload3}
          fileUploadClose={fileUploadClose2}
          tableData={subTableData}
          title={subTitle}
          subTitle={tableSubTitle}
        />
      ) : (
        <div></div>
      )}
      <Dialog
        maxWidth={
          tableCols.length <= 5 ? 'sm' : tableCols.length <= 9 ? 'md' : 'lg'
        }
        fullWidth={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <LoadingOverlay active={isLoading} spinner text="Loading ...">
          <DialogContent>
            <center>
              <b>{title}</b>
            </center>
            {
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card style={{ marginTop: '4px' }}>
                    <CardBody>
                      <div className={'material-grid-wrapper'}>
                        <MaterialTable
                          size="small"
                          title={
                            <div>
                              <span className={'grid-title-icon'}>
                                <ListAltOutlinedIcon />
                              </span>
                              <Typography
                                variant="h6"
                                style={{
                                  fontSize: '1rem',
                                  marginTop: '-0.7rem',
                                }}
                              >
                                {subTitle}
                              </Typography>
                            </div>
                          }
                          columns={tableCols}
                          data={tableData}
                          options={{
                            actionsColumnIndex: -1,
                            selectionProps: (rowData) => {},
                            pageSize: 10,
                            sorting: false,
                            search: false,
                            padding: 'dense',
                            maxBodyHeight: '40vh',

                            headerStyle: {
                              fontSize: '0.7rem',
                              textAlignL: 'center',
                            },
                            rowStyle: {
                              fontSize: '0.6rem',
                              fontWeight: 450,
                              lineHeight: 100,
                            },
                          }}
                          onRowClick={(event, rowData, toggleDetailPanel) => {
                            const data = assignTableData(rowData.subTableData);
                            setSubTableData(data);
                            const sub = `SR-ID ${rowData.srId.value} | ${rowData.customerName.value}`;
                            setTableSubTitle(sub);
                            setOpenFileUpload3(true);
                          }}
                        />{' '}
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            }
          </DialogContent>
          <DialogActions>
            <Button
              className={'generic-button-class div-button-wrapper'}
              onClick={handleClose}
              color="primary"
              variant="contained"
            >
              Cancel
            </Button>
            {/* spreedSheetData */}
          </DialogActions>
        </LoadingOverlay>
      </Dialog>
    </div>
  );
}
