import axios from '../../axios/axios-default';
import urlProperties from '../../Util/Property/UrlProperties';

const baseUrl = urlProperties.baseUrl;
export default {
  getAllUserForClaims() {
    return axios.get(`${baseUrl}getUsers`);
  },
  getAllCustomers() {
    return axios.get(`${baseUrl}${urlProperties.getCustomers}`);
  },
  getCustomersByName(name) {
    return axios.get(`${baseUrl}${urlProperties.getCustomers}/${name}`);
  },
  getAllProductCategories() {
    return axios.get(`${baseUrl}getProductCategoriesByStatus/true`);
  },
  getProductBrandsByCategory(categoryId) {
    return axios.get(
      `${baseUrl}getProductBrandsByStatus?categoryId=${categoryId}&active=true`
    );
  },
  getProductModelByBrand(brandId) {
    return axios.get(
      `${baseUrl}getProductModelsByStatus?brandId=${brandId}&active=true`
    );
  },
  getServiceRequestCategories() {
    return axios.get(`${baseUrl}getServiceRequestCategories`);
  },
  getServiceRequestPriorities(
    customerId,
    categoryId,
    brandId,
    modelId,
    requestTypeId
  ) {
    return axios.get(
      `${baseUrl}getAllMatchingPriorityConfiguration?customerId=${customerId}&categoryId=${categoryId}&brandId=${brandId}&modelId=${modelId}&requestTypeId=${requestTypeId}`
    );
  },
  getServiceAgreementCategories() {
    return axios.get(
      `${baseUrl}${urlProperties.productServiceAgreement.getProductServiceAgreements}`
    );
  },
  getChildCustomers(parentCustomerId) {
    return axios.get(`${baseUrl}getAllChildCustomers/${parentCustomerId}`);
  },
  getTechnicianJourneyRoutes(date, techId) {
    return axios.get(
      'getTotalJourneyByDate?date=' + date + '&userId=' + techId
    );
  },
  getUserJourneyByDate(userId, date) {
    return axios.get(`getUserJourneyByDate?userId=${userId}&date=${date}`)
  },
  getUserJourneyReportByDate(userId, date) {
    return axios.get(`getUserJourneyReportByDate?userId=${userId}&date=${date}`)
  },
  searchOperationalReport(data) {
    return axios.post(`${baseUrl}createCustomerByMobileUser`, data);
  },
  getActiveWorkflowByTypeAndSrTypeId(id) {
    return axios.get(
      'getActiveWorkflowByTypeAndSrTypeId?type=SERVICE_REQUEST&srTypeId=' + id
    );
  },

  getStoredJourneyReport(technicianId, date) {
    return axios.get(
      `getSavedApiDirectionDetails?userId=${technicianId}&date=${date}`
    )
  },

  storeJourneyReport(journeyObject, technicianId, date) {
    return axios.post(
      `saveJourneyDirectionApiDetails?userId=${technicianId}&date=${date}`, journeyObject
    )
  }
};
// export function getActiveWorkflowByTypeAndSrTypeId (id) {
//   return axios.get("getActiveWorkflowByTypeAndSrTypeId?type=SERVICE_REQUEST&srTypeId="+id);
// }
