import React, { useContext, useEffect, useRef } from "react";
import CustomMarker from "./CustomMarker";
import { gpsMarker } from "./SvgIcons";
import { RouteReportContext } from "views/Reports/TechnicianRouteReport";

const RouteMarker = ({
  srId,
  route,
  origin,
  destination,
  markerChangeLatency: defaultLatency,
  routeColor,
}) => {
  const intervalRef = useRef();

  const { selectedTravelRoute, routeIndexRef, routeMarker, routeMarkerHandlers, routeMarkerRef } = useContext(RouteReportContext);
  const { isPlaying, isGpsFocused, routeLength, markerChangeLatency } = routeMarker;
  const { handlePlay, handleMarkerChange } = routeMarkerHandlers;

  const isThisSelectedRoute = selectedTravelRoute?.id === srId;

  const currentRouteIndexRef = useRef(0);
  const markerRef = useRef();

  useEffect(() => {
    if (!route) return;
    if (isThisSelectedRoute) return;
    const interval = setInterval(() => {
      if (route.length === currentRouteIndexRef.current)
        clearInterval(interval);
      if (markerRef.current)
        markerRef.current.setPosition(route[currentRouteIndexRef.current++]);
    }, defaultLatency);

    return () => {
      clearInterval(interval);
    };
  }, [route, selectedTravelRoute]);

  useEffect(() => {
    if (markerChangeLatency === 0) return;
    if (!(route && isThisSelectedRoute)) return;
    if (!selectedTravelRoute) return;

    routeMarkerRef.current = markerRef.current;

    if (!isPlaying) clearInterval(intervalRef.current);
    else {
      intervalRef.current = setInterval(() => {
        if (routeLength === routeIndexRef.current){
          clearInterval(intervalRef.current);
          if(isPlaying) handlePlay();
          routeIndexRef.current = 0;
        }
        else if (routeMarkerRef.current){
          routeMarkerRef.current.setPosition(route[routeIndexRef.current++]);
          handleMarkerChange();
        }
      }, markerChangeLatency)
    }
    return () => {
      clearInterval(intervalRef.current)
    }

  },[route, isPlaying, isGpsFocused, selectedTravelRoute, markerChangeLatency])

  const gpsMarkerIcon = gpsMarker(routeColor);

  return (
    <CustomMarker
      position={origin}
      markerRef={markerRef}
      icon={gpsMarkerIcon}
      iconSize={30}
      anchorPoint={15}
    />
  );
};

export default RouteMarker;
