import React, { createContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { fetchJourneyRoute } from './utils/journey-utils';
import Sidebar from "./Sidebar"
import ReportMap from 'views/Maps/ReportMap';
import TimeStampViewerPanel from './TimeStampViewerPanel';
import { getFormattedDateTime } from './utils/datetime-utils';

const tempCenter = {
    lat: 6.850401188194636,
    lng: 79.87379161670415,
  };

export const RouteReportContext = createContext();

const TechnicianRouteReport = () => {
    const { technicianId, date} = useParams();
    const [customerList, setCustomerList] = useState([]);
    const [center, setCenter] = useState(tempCenter);
    const googleMapRef = useRef(null);

    const [isLoading, setIsLoading] = useState(true);
    const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false);

    const [travelRoutes, setTravelRoutes] = useState([]);
    const [hasError, setHasError] = useState(false);
    
    const routeIndexRef = useRef(null);
    const routeMarkerRef = useRef(null);
    const offsetY = useRef(null);
    const circleRef = useRef(null);
    const lineRef = useRef(null);
    const timeBoxRef = useRef(null);

    const [routeMarker, setRouteMarker] = useState({
      isPlaying: false,
      isGpsFocused: false,
      isDragging: false,
      routeLength: 1,
      markerChangeLatency: 0,
    });
    const [selectedTravelRoute, setSelectedTravelRoute] = useState(null);


    useEffect(() => {
      if (!selectedTravelRoute) {
        routeIndexRef.current = 0;
        handleCircleMove()
        return;
      };
      const routeLength = selectedTravelRoute.journey.waypoints.length;
      setRouteMarker({
        isPlaying: true,
        isGpsFocused: true,
        routeLength,
        markerChangeLatency: routeLength > 1250 ? 4 : 5000/routeLength, 
        // minimum markerchange latency is 4ms,
      });
      routeIndexRef.current = 0;
    },[selectedTravelRoute])


    useEffect(() => {
      if (!(technicianId && date)) return;
      if (!isGoogleMapLoaded) return;
        fetchJourneys();
    },[technicianId, date, isGoogleMapLoaded]);

    useEffect(() => {
        if (!travelRoutes) return;
        getCustomers();
    },[travelRoutes])

    const handleCircleMove = (timestamp) => {
      if (circleRef.current && lineRef.current) {
        circleRef.current.style.top = `${(routeIndexRef.current * lineRef.current.clientHeight/routeMarker.routeLength) - 10}px`
      }
      if (!timestamp) return;
      const currentTimestamp = getFormattedDateTime(timestamp)?.time
      if (timeBoxRef.current && currentTimestamp)
        timeBoxRef.current.innerHTML = currentTimestamp
    }

    const getCurrentMarker = () => selectedTravelRoute?.journey.waypoints[routeIndexRef.current];

    const handleMarkerChange = () => {
      const currentMarker = getCurrentMarker()
      if (routeMarker.isGpsFocused)
        googleMapRef.current.setCenter(currentMarker?.location);
      handleCircleMove(currentMarker?.timestamp)
    }
      

    const handlePlay = () => {
      setRouteMarker(prev => ({...prev, isPlaying: !routeMarker.isPlaying}))
    }
    
    const handleReplay = () => {
      routeIndexRef.current = 0;
      setRouteMarker(prev => ({...prev, isPlaying: true}));
    }

    const handleGpsFocus = () => {
      setRouteMarker(prev => ({...prev, isGpsFocused: !routeMarker.isGpsFocused}));
      const marker = getCurrentMarker();
      if(googleMapRef.current && !routeMarker.isGpsFocused) googleMapRef.current.setCenter(marker?.location)
    }

    const handleMouseDownOnCircle = (e) => {
      if (routeMarker.isPlaying) handlePlay();
      setRouteMarker(prev => ({...prev, isDragging: true}))
      offsetY.current = e.clientY - circleRef.current.offsetTop;
      document.addEventListener("mousemove", handleMouseMoveOnCircle);
      document.addEventListener("mouseup", handleMouseUpOnCircle);
    }

    const handleMouseMoveOnCircle = (e) => {
      const position = e.clientY - offsetY.current;
      if (position < -10 || position > lineRef.current.clientHeight - 10) return;
      routeIndexRef.current = Math.round((routeMarker.routeLength * (position + 10)) / lineRef.current.clientHeight);
      if(routeMarkerRef.current) {
        const markerPosition = getCurrentMarker()
        routeMarkerRef.current.setPosition(markerPosition?.location)
        handleMarkerChange();
      }
      circleRef.current.style.top = `${e.clientY - offsetY.current}px`
    }

    const handleMouseUpOnCircle = () => {
      setRouteMarker(prev => ({...prev, isDragging: false}));
      document.removeEventListener("mousemove", handleMouseMoveOnCircle);
      document.removeEventListener("mouseup", handleMouseUpOnCircle);
    }

    const handleJourneyView = (travelRouteSrId, isSelected) => {
        if (isSelected) {
            const { journey } = travelRoutes.find(travelRoute => travelRoute.id === travelRouteSrId);
            const { origin } = journey;
            setCenter(origin || center);
        }
        setTravelRoutes(travelRoutes.map(travelRoute => {
            if (travelRoute.id === travelRouteSrId) travelRoute.isSelected = isSelected
            return travelRoute;
        }))
    }

    const handleAllSelected = () => {
        setTravelRoutes(travelRoutes.map(travelRoute => ({...travelRoute, isSelected: true})))
    }

    const handleNoneSelected = () => {
        setTravelRoutes(travelRoutes.map(traveRoute => ({...traveRoute, isSelected: false})))
    }

    const fetchJourneys = async () => {
        try {
            const fetchedTravelRoutesList = await fetchJourneyRoute(technicianId, date);
            const mainJourney = fetchedTravelRoutesList.find(travelRoute => travelRoute.id === -1);
            setCenter(mainJourney?.journey?.origin || tempCenter)
            setTravelRoutes(fetchedTravelRoutesList);
        }
        catch (e) {
            console.error("Error occured while fetching journeys", e)
            setHasError(true);
        }
        finally{
          setIsLoading(false);
        }
    }

    const getCustomers = () => {
        const filteredDustomerList = travelRoutes.map(travelRoute => ({...travelRoute.customer, isSelected: true}));
        setCustomerList(filteredDustomerList);
    }

    const routeMarkerHandlers={
      handlePlay,
      handleReplay,
      handleGpsFocus,
      handleMarkerChange,
      handleMouseDownOnCircle
    };

  return (
    <div>
      <RouteReportContext.Provider value={{
        technicianId,
        date,
        travelRoutes,
        handleJourneyView,
        handleAllSelected,
        handleNoneSelected,
        setIsGoogleMapLoaded,
        hasError,
        center,
        customerList,
        isLoading,
        googleMapRef,
        
        // props for handling route marker
        selectedTravelRoute,
        routeMarker,
        routeIndexRef,
        routeMarkerRef,
        routeMarkerHandlers
      }}>
        <Sidebar />
        <ReportMap />
        <TimeStampViewerPanel timeBoxRef={timeBoxRef} lineRef={lineRef} circleRef={circleRef} setSelectedTravelRoute={setSelectedTravelRoute} />
      </RouteReportContext.Provider>
    </div>
  )
}

export default TechnicianRouteReport
