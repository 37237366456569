import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FullWidthTabPanel from 'components/TabPanels/FullWidthTabPanel';
import LoadingOverlay from 'react-loading-overlay';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import PermissionProperties from 'Util/Property/PermissionProperties';
import styles from './Customer.module.css';
import Customer from 'views/Customers/Customer/Customer';
import Products from 'views/Products/Products';
import Estimations from 'views/Estimations/Estimations';
import ServiceRequests from 'views/ServiceRequests/ServiceRequests';
import CustomerFeedback from 'views/Customers/Customer/Feedback/CustomerFeedbacks';
import RouterProps from '../../../Util/Property/RouterProperties';
import Spinner from 'components/Spinner/Spinner';

const CustomerDetails = (props) => {
  const [customerId, setCustomerId] = useState(0);
  const [permissions, setPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isStateChanged, setIsStateChanged] = useState(false);
  let customerDetailsTab = 'customerDetailsTab';
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem(customerDetailsTab, newValue);
  };

  useEffect(() => {
    let userId;
    const query = new URLSearchParams(props.location.search);
    let queryParam = {};
    for (let param of query.entries()) {
      queryParam[param[0]] = param[1];
    }
    if (typeof queryParam['id'] != 'undefined') {
      userId = queryParam['id'];
      setCustomerId(userId);
      if (queryParam['isEdit'] === 'true') {
        setIsEdit(true);
      }
    } else if (
      window.location.pathname ==
      RouterProps.mainDomain + '/customer-dashboard'
    ) {
      const loggedInUserCustomerId = localStorage.getItem('userCustomerId');
      setCustomerId(loggedInUserCustomerId);
    }
    // this is for to stop Rerendering of UserComponent when viewing user
    setIsStateChanged(true);

    // if (userId > 0) {
    //     getUserData(userId);
    // }
    let permissionArray = [PermissionProperties.passwordChange];
    setPermissions(checkPermissoinListAvailable(permissionArray));
    let tab = localStorage.getItem(customerDetailsTab);
    if (typeof tab !== 'undefined' && tab !== null) {
      setValue(parseInt(tab));
    }
  }, []);

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <AppBar
                  position="static"
                  color="inherit"
                  className={styles.appBarStyle}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Customer" {...a11yProps(0)} />

                    <Tab label="Products" {...a11yProps(1)} />

                    <Tab label="Service Requests" {...a11yProps(2)} />

                    {/* <Tab label="Estimations" {...a11yProps(3)} /> */}

                    {/* <Tab label="Feedback" {...a11yProps(4)} /> */}
                  </Tabs>
                </AppBar>
                <FullWidthTabPanel
                  value={value}
                  index={0}
                  dir={theme.direction}
                >
                  <Customer {...props} id={customerId} isEdit={isEdit} />
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={1}
                  dir={theme.direction}
                >
                  <Products {...props} id={customerId} />
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={2}
                  dir={theme.direction}
                >
                  `<ServiceRequests {...props} id={customerId} />`
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={3}
                  dir={theme.direction}
                >
                  <Card>
                    <Estimations {...props} customerId={customerId} />
                  </Card>
                </FullWidthTabPanel>
                <FullWidthTabPanel
                  value={value}
                  index={4}
                  dir={theme.direction}
                >
                  <card>
                    <CustomerFeedback {...props} customerId={customerId} />
                  </card>
                </FullWidthTabPanel>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default CustomerDetails;
