import React from "react";
import { Box, Checkbox, styled, Tooltip, Typography } from "@material-ui/core";
import hexToRgba from "hex-to-rgba";
import { useContext, useEffect, useState } from "react";
import { FaHourglassStart } from "react-icons/fa";
import { HiClock } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { RouteReportContext } from "../..";
import { durationCalculator, getFormattedDateTime } from "../../utils/datetime-utils";

const FlexBox = styled(Box)(_ => ({
    width:"100%",
    display:"flex",
    alignItems:"center",
}))

const JourneyCard = ({ travelRoute }) => {
  const { handleJourneyView } = useContext(RouteReportContext);

  const {
    id,
    name,
    routeColor,
    distance,
    isSelected,
    customer,
    startTime,
    endTime,
  } = travelRoute;
  const customerName = customer?.name;

  const [timeDifference, setTimeDifference] = useState();
  const [dateTimeValue, setDateTimeValue] = useState();

  useEffect(() => {
    if (!startTime || !endTime) return;
    setDateTimeValue(getDateField(startTime, endTime));
    setTimeDifference(durationCalculator(startTime, endTime, true));
  },[startTime, endTime])

  const handleChange = () => {
    handleJourneyView(id, !isSelected);
  };

  return (
    <Box width="100%">
      <Box
        onClick={handleChange}
        sx={{
          py: "10px",
          px: "15px",
          borderRadius: "10px",
          backgroundColor: "white",
          height: isSelected ? "200px" : "30px",
          transition: "all 0.25s ease-out",
          cursor: "pointer",
          overflow: "hidden",
        }}
      >
        <FlexBox justifyContent="space-between" >
            <Tooltip title={customerName?.toUpperCase() || ""}>
                <FlexBox justifyContent="start" overflow="hidden" whiteSpace="nowrap" maxWidth="90%">
                    <Checkbox size="small" checked={isSelected} onChange={handleChange} style={{color:routeColor}} />
                    <Typography style={{fontWeight:600, fontSize:"13px", lineHeight:"12px", textOverflow:"ellipsis", overflow:"hidden"}}>
                        {id === -1 ? name?.toUpperCase() 
                        : <span style={{position:"relative", top:"2px"}}>{customerName?.toUpperCase()}
                        <br />
                        {id !== -1 && <span style={{fontSize:"10px"}}>{name?.toUpperCase()}</span>}</span>}
                    </Typography>
                </FlexBox>
            </Tooltip>
            <Box sx={{fontSize:"24px", transform: isSelected ? "rotate(0deg)": "rotate(-90deg)", transition: "all 0.25s ease-out"}}>
                <MdOutlineKeyboardArrowDown />
            </Box>
        </FlexBox>
        <FlexBox marginTop="20px" flexDirection="column" style={{gap:"10px"}}>
            <JourneyField Icon={ImLocation} value={`${Number(distance).toFixed(2)} KM`} color={routeColor}/>
            <JourneyField Icon={HiClock} value={dateTimeValue} color={routeColor}/>
            <JourneyField Icon={FaHourglassStart} value={timeDifference} color={routeColor}/>
        </FlexBox>
      </Box>
    </Box>
  );
};

const JourneyField = ({Icon, value, color}) => (
    <Box width="100%">
        <Box sx={{fontSize:"14px", fontWeight:600, padding:"10px", textTransform:"uppercase", backgroundColor: color && hexToRgba(color, 0.1), borderRadius:"10px", maxHeight:"30px"}}>
            <FlexBox justifyContent="start" style={{gap:"20px"}}>
                <Icon style={{fontSize: "20px", color}} />
                <Box>{value}</Box>
            </FlexBox>
        </Box>
    </Box>
);


const getDateField = (startTimeStamp, endTimeStamp) => {
    const { date: startDate, time: startTime } = getFormattedDateTime(startTimeStamp);
    const { date: endDate, time: endTime } = getFormattedDateTime(endTimeStamp);

    const isDateEquals = startDate === endDate;

    return (
        <FlexBox justifyContent="start" alignItems="end" style={{gap:"8px"}} lineHeight="13px">
            <DateField date={!isDateEquals && startDate} time={startTime} /> - <DateField date={!isDateEquals && endDate} time={endTime} />
        </FlexBox>
    );
}

const DateField = ({date, time}) => (
    <div>
      {date && <div style={{ fontSize: "10px" }}>{date}</div>}
      <div>{time}</div>
    </div>
  );

export default JourneyCard;
