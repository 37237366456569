import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import PermissionProperties from 'Util/Property/PermissionProperties';
import { notyDefault, notyTypes } from 'components/Noty/NotyCustom';
import { checkPermissoinListAvailable } from 'Util/Permission/CheckPermission';
import Button from 'components/CustomButtons/Button.js';
import urlProperties from 'Util/Property/UrlProperties';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomInput from 'components/CustomInput/CustomInput.js';
import { Form, Field } from 'react-final-form';
import generalProperties from 'Util/Property/GeneralProperties';
import MaterialTable from 'material-table';
import ListAltOutlinedIcon from '@material-ui/icons/ViewColumnSharp';
import { Typography } from '@material-ui/core';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import { buildFilterObject } from '../../../../Util/Util';
import Spinner from 'components/Spinner/Spinner.js';
import styles from '../ServiceRequestsSettings.module.css';
import {
  deleteServiceRequestSalesType,
  createAndEditServiceRequestSalesType,
  getServiceRequestalesTypeCall,
} from 'services/ServiceRequest/serviceRequestSalesTypeServices';
import {
  getSalesTypeForm,
  serviceRequestSalesTypeProperties,
} from 'Util/Property/ServiceRequestSalesTypeProperties';

const ServiceRequestsSalesTypeTable = (props) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [permissions, setPermissions] = useState({});
  const [salesTypeForm, setSalesTypeForm] = useState(getSalesTypeForm());
  useEffect(() => {
    getServiceRequestSalesTypes();
  }, []);
  const [requestSalesTypeDeleteRowData,setRequestSalesTypeDeleteRowData,] = useState({});
  const [serviceRequestSalesTypes, setServiceRequestSalesTypes] = useState();
  const [isDeleteDialogOpen,setDeleteDialogOpen,] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dialogDetails, setDialogDetails] = useState({
    attributeDialogHeader: serviceRequestSalesTypeProperties.editSalesType,
    isEditDialog: false,
    selectedId: 0,
  });
  const [initialData, setInitialData] = useState({});
  useEffect(() => {
    let permissionArray = [
      PermissionProperties.addServiceRequestCategory,
      PermissionProperties.editServiceRequestCategory,
      PermissionProperties.deleteServiceRequestCategory,
    ];
    setPermissions(checkPermissoinListAvailable(permissionArray));
  }, []);

  const tableRef = useRef(null);

  const deleteDialogCloseHandler = () => {
    setDeleteDialogOpen(false);
  };
  const deleteDialogOpenHandler = () => {
    setDeleteDialogOpen(true);
  };
  const dialogBoxCloseHandler = () => {
    setIsDialogOpen(false);
  };

  const deleteConfirmHandler = async () => {
    setIsDeleting(true);
    await deleteServiceRequestSalesType(
      urlProperties.serviceRequestSalesType.deleteServiceRequestSalesType +
        requestSalesTypeDeleteRowData.id,
      'DELETE',
      requestSalesTypeDeleteRowData.id
    )
      .then((response) => {
        if (response.status === 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: generalProperties.message.success.delete,
          });
        } else if (!response.success && !response.isRequestFailed) {
          notyDefault({ type: notyTypes.ERROR, text: response.data });
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: generalProperties.message.error.delete,
          });
        }
        setIsDeleting(false);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: generalProperties.message.error.delete,
        });
        setIsDeleting(false);
      });
    tableRef.current.onQueryChange();
    deleteDialogCloseHandler();
  };

  const setSalesTypeFormValues = (name, status) => {
    let formData = { ...salesTypeForm };
    let elementData = formData['name']; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = name;
    elementData.inputProps = inputProps;
    formData['name'] = elementData;
    elementData = formData['status']; // get data for form key
    inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = status;
    elementData.inputProps = inputProps;
    formData['status'] = elementData;
    setSalesTypeForm(formData);
  };
  const editRow = (isEdit, rowData, isDelete, isAdd) => {
    let dialogDetails = { ...dialogDetails };
    dialogDetails.isEditDialog = isEdit;
    if (isEdit) {
      dialogDetails.attributeDialogHeader =
        serviceRequestSalesTypeProperties.editSalesType;
        setSalesTypeFormValues(rowData.name, rowData.status);
      setInitialData({ name: rowData.name, status: rowData.status });
      dialogDetails.selectedId = rowData.id;
    } else if (isAdd) {
      setSalesTypeFormValues('', 'ACTIVE');
      dialogDetails.attributeDialogHeader =
        serviceRequestSalesTypeProperties.addNewSalesType;
      setInitialData({ name: '' });
    } else if (isDelete) {
      setRequestSalesTypeDeleteRowData(rowData);
      deleteDialogOpenHandler();
      return;
    }
    setDialogDetails(dialogDetails);
    setIsDialogOpen(true);
  };
  const getServiceRequestSalesTypes = () => {
    setIsLoading(true);
    getServiceRequestalesTypeCall().then((response) => {
      if (!response.success) {
        notyDefault({
          type: notyTypes.ERROR,
          text: serviceRequestSalesTypeProperties.message.error.loadingError,
        });
      }
      console.log('data from table', response.data);
      setServiceRequestSalesTypes(response.data);
      setIsLoading(false);
    });
  };
  const onRecordSubmit = async (values) => {
    let data = {
      name: salesTypeForm.name.inputProps.value,
      status: salesTypeForm.status.inputProps.value,
    };
    if (dialogDetails.isEditDialog) {
      data['id'] = dialogDetails.selectedId;
    }
    setIsLoading(true);
    createAndEditServiceRequestSalesType(
      data,
      dialogDetails.isEditDialog
    )
      .then((result) => {
        if (result.status == 200) {
          notyDefault({
            type: notyTypes.SUCCESS,
            text: generalProperties.message.success.dataSave,
          });
        } else if (result.success && !result.isRequestFailed) {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data,
          });
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: generalProperties.message.error.dataSave,
          });
        }
        setIsDialogOpen(false);
        tableRef.current.onQueryChange();
        setIsLoading(false);
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: generalProperties.message.error.dataSave,
        });
        setIsLoading(false);
      });
  };

  const onChangeHandeler = (event, elId) => {
    let formData = { ...salesTypeForm };
    let elementData = formData[elId]; // get data for form key
    let inputProps = { ...elementData.inputProps }; //  copy input data
    inputProps.value = event.target.value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setSalesTypeForm(formData);
  };

  let formElementArray = [];
  for (let key in salesTypeForm) {
    formElementArray.push({
      id: key,
      config: salesTypeForm[key],
    });
  }

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Dialog
                  open={isDeleteDialogOpen}
                  onClose={deleteDialogCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {serviceRequestSalesTypeProperties.deleteSalesType}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="sm"
                      onClick={deleteDialogCloseHandler}
                      color="primary"
                    >
                      {generalProperties.cancel}
                    </Button>
                    <Button
                      size="sm"
                      onClick={deleteConfirmHandler}
                      color="primary"
                      autoFocus
                      disabled={isDeleting}
                    >
                      {generalProperties.confirm}
                    </Button>
                  </DialogActions>
                </Dialog>
                <Dialog
                  fullWidth={true}
                  maxWidth="xs"
                  open={isDialogOpen}
                  onClose={dialogBoxCloseHandler}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <LoadingOverlay
                    active={isLoading}
                    spinner={<Spinner />}
                    text="Loading ..."
                  >
                    <DialogTitle>
                      {' '}
                      {dialogDetails.attributeDialogHeader}{' '}
                    </DialogTitle>
                    <Form
                      onSubmit={(values) =>
                        onRecordSubmit(values, false)
                      }
                      initialValues={initialData}
                      validate={(values, ss) => {
                        const errors = {};
                        if (!values.name) {
                          errors.name = generalProperties.emptyField;
                        }
                        return errors;
                      }}
                      render={({
                        handleSubmit,
                        reset,
                        submitting,
                        pristine,
                        valid,
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            {formElementArray.map((element) => {
                              if (!element.config.isHide) {
                                let mdVal = 12;
                                if (!element.config.isFullWidth) {
                                  mdVal = 6;
                                }

                                return (
                                  <GridItem
                                    key={element.id}
                                    xs={12}
                                    sm={12}
                                    md={mdVal}
                                  >
                                    <Field name={element.id}>
                                      {({ input, meta, options, value }) => (
                                        <div style={{ position: 'relative' }}>
                                          <GridContainer>
                                            <GridItem xs={10} sm={10} md={10}>
                                              <CustomInput
                                                labelText={element.config.label}
                                                id={element.id}
                                                inputProps={{
                                                  ...input,
                                                  ...element.config.inputProps,
                                                }}
                                                type={element.config.elType}
                                                formControlProps={
                                                  element.config
                                                    .formControlProps
                                                }
                                                changed={(event, value) => {
                                                  input.onChange(event);
                                                  onChangeHandeler(
                                                    event,
                                                    element.id
                                                  );
                                                }}
                                                labelProps={{
                                                  ...element.config.labelProps,
                                                  error:
                                                    meta.error && meta.touched,
                                                }}
                                              />
                                            </GridItem>
                                          </GridContainer>
                                          {meta.error && meta.touched && (
                                            <span>{meta.error}</span>
                                          )}
                                        </div>
                                      )}
                                    </Field>
                                  </GridItem>
                                );
                              }
                            })}
                          </DialogContent>
                          <DialogActions>
                            <Button
                              size="sm"
                              onClick={dialogBoxCloseHandler}
                              color="primary"
                            >
                              {generalProperties.cancel}
                            </Button>
                            <Button
                              size="sm"
                              type="submit"
                              disabled={
                                submitting || !valid || pristine || isLoading
                              }
                              color="primary"
                              autoFocus
                            >
                              {generalProperties.save}
                            </Button>
                          </DialogActions>
                        </form>
                      )}
                    />
                  </LoadingOverlay>
                </Dialog>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    tableRef={tableRef}
                    components={{
                      OverlayLoading: () => (
                        <div className={styles.loadingOverlay}>
                          <Spinner />
                        </div>
                      ),
                    }}
                    actions={[
                      {
                        icon: 'edit',
                        tooltip: 'Edit',
                        iconProps: { className: 'action-buttons edit-button' },
                        hidden: !permissions[
                          PermissionProperties.editServiceRequestCategory
                        ],
                        onClick: (event, rowData) => {
                          editRow(true, rowData, false, false);
                        },
                      },
                      {
                        icon: () => (
                          <div
                            className={
                              'generic-button-class div-button-wrapper'
                            }
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              editRow(false, null, false, true)
                            }
                          >
                            {serviceRequestSalesTypeProperties.addNewSalesType}
                          </div>
                        ),
                        tooltip: 'Add Service Request Sales type',
                        isFreeAction: true,
                        hidden: !permissions[
                          PermissionProperties.addServiceRequestCategory
                        ],
                      },
                    ]}
                    columns={[
                      { title: 'Name', field: 'name' },
                      { title: 'Status', field: 'status' },
                    ]}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        let url =
                          urlProperties.baseUrl +
                          urlProperties.serviceRequestSalesType
                            .filterServiceRequestSalesType +
                          '?';
                        url += 'perPage=' + query.pageSize;
                        url += '&page=' + query.page;
                        url +=
                          '&sortColumn=' +
                          (typeof query.orderBy === 'undefined'
                            ? 'id'
                            : query.orderBy.field);
                        url += '&sortOrder=' + query.orderDirection;
                        url += '&search=' + query.search;
                        fetch(url, {
                          headers: {
                            Authorization:
                              'Bearer ' + localStorage.getItem('token'),
                            'Content-Type': 'application/json',
                          },
                          method: 'POST',
                          body: JSON.stringify(buildFilterObject(query)),
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            resolve({
                              data: result.data,
                              page: result.page,
                              totalCount: result.total,
                            });
                          });
                      })
                    }
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          Service Request Sales type
                        </Typography>
                      </div>
                    }
                    options={{
                      actionsColumnIndex: -1,
                      filtering: true,
                      sorting: false,
                      search: false,
                      maxBodyHeight: '70vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                      },
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};
export default ServiceRequestsSalesTypeTable;
