import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Styles from './Style/Style';
import moment from 'moment';
import {
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Brush,
  ResponsiveContainer,
} from 'recharts';
import DateRange from './Component/DateRange';
import Frequency from './Component/Frequency';
import TimeFrequency from './Component/TimeFrequency';
import Selection from './Component/Selection';
import { getAvgTimeBarChart } from 'services/CustomBarChart/CustomBarChartServices';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import {minutesToDaysHoursMinutes , minutesToHoursMinutes} from '../../Util/Converters/TimeConverters'

const useStyles = makeStyles(Styles);
var averageColor = '#53315E';
var colorArray = [
  '#CD113B',
  '#520718',
  '#7b0a23',
  '#cd113b',
  '#dc5876',
  '#e6889d',
  '#3366E6',
  '#B34D4D',
  '#E6B333',
  '#66991A',
  '#999966',
  '#FFFF99',
  '#99FF99',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
];

var monthList = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const data = [
  {
    date: '2022-09-07',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-08',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-09',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-10',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-11',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-12',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-13',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-14',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-15',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-16',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-17',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-18',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-19',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-20',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-21',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-22',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-23',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-24',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-25',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-26',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-27',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-28',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-29',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-09-30',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-01',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-02',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-03',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-04',
    'Ishara Rashi': 1.0,
    'Ashan Manathunga': 1.0,
    'Prageeth Fernando': 1.0,
    'Admin Cba': 1.0,
    'Layantha Lakaranda': 1.0,
    'Jackson Kugan': 1.0,
  },
  {
    date: '2022-10-05',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 1.0,
    'Prageeth Fernando': 1.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-06',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-07',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-08',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 1.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-09',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 0.0,
    'Jackson Kugan': 0.0,
  },
  {
    date: '2022-10-10',
    'Ishara Rashi': 0.0,
    'Ashan Manathunga': 0.0,
    'Prageeth Fernando': 0.0,
    'Admin Cba': 0.0,
    'Layantha Lakaranda': 6.0,
    'Jackson Kugan': 0.0,
  },
];

export default function CustomBarChart(props) {
  const {
    endPoint,
    serviceRequestCategories,
    products,
    users,
    selection,
    title,
    dataSet,
    highlightsLoading,
  } = props;
  const classes = useStyles();

  const chartDataLoading = false;
  // initialStartDate.setMonth(initialStartDate.getMonth() - 1);

  const [frequency, setFrequency] = React.useState(1);
  const [scale, setScale] = React.useState(2);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const [selectionData, setSelectionData] = React.useState([]);
  const [legendData, setlegendData] = React.useState([]);
  const [legendDataSelections, setlegendDataSelections] = React.useState([]);
  const [chartStartDate, setChartStartDate] = React.useState(
    new Date(
      moment()
        .startOf('month')
        .toDate()
    )

    // new Date(
    //   moment()
    //     .subtract(1, 'months')
    //     .startOf('month')
    //     .toDate()
    // )
  );
  const [chartEndDate, setChartEndDate] = React.useState(
    // new Date(
    //   moment()
    //     .endOf('month')
    //     .toDate()
    // )
    new Date()
  );
  const [dataSeries, setDataSeries] = React.useState([]);
  const [dataSeriesBydate, setDataSeriesByDate] = React.useState([]);
  const [dataSeriesByFrequency, setDataSeriesByFrequency] = React.useState([]);
  const [barChartData, setBarChartData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(highlightsLoading);

  useEffect(() => {
    filterByFrequency(frequency);
  }, [dataSeriesBydate]);

  useEffect(() => {
    filterBySelection(selectedValue);
  }, [dataSeriesByFrequency]);

  useEffect(() => {
    filterByFrequency(frequency);
  }, [frequency]);

  useEffect(() => {
    filterBySelection(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    setlegendDataSelections(legendData);
  }, [legendData]);

  useEffect(() => {
    const selected = selectionData.map((obj) => obj.name);
    setSelectedValue(selected);
  }, [selectionData]);

  const onChartDateChange = async (dates) => {
    const [start, end] = dates;
    setChartStartDate(start);
    setChartEndDate(end);
    if (start && end) {
      await getData(start, end);
    }
  };

  const filterBySelection = (selection) => {
    const dataSet = JSON.parse(JSON.stringify(dataSeriesByFrequency));

    if (selection.length > 0) {
      const data = dataSet.map((el) => {
        const obj = { date: el.date };
        selection.forEach((key) => {
          obj[key] = el[key];
        });
        return obj;
      });

      setBarChartData(data);
      var legendArray = legendData.filter((obj) => {
        return selection.includes(obj.name);
      });

      if (legendArray.length === 0) {
        setlegendDataSelections(legendData);
      } else {
        setlegendDataSelections(legendArray);
      }
    } else {
      setBarChartData([]);
      setlegendDataSelections([]);
    }
  };

  const getSelectionData = (data) => {
    const keys = Object.keys(data[0])
      .filter((value) => value !== 'date' && value !== 'week_number')
      .map((el, index) => {
        return { name: el, id: index };
      });
    setSelectionData(keys);
    setlegendData(keys);
  };


  const mapByScale = (value) =>{
    switch (scale) {
      case 1:
        return minutesToDaysHoursMinutes(value);
      case 2:
        return minutesToHoursMinutes(value);
      case 3:
        return `${value} min`;
      default:
        return `${value} min`;
    }
  }

  const formatScale = (value) => {
    switch (scale) {
      case 1:
        return value / 1440;
      case 2:
        return value / 60;
      case 3:
        return value;
      default:
        return value;
    }
  }

  const filterByFrequency = (frequency) => {
    switch (frequency) {
      case 1:
        filterByDaily();
        break;
      case 2:
        filterByWeekly();
        break;
      case 3:
        filterByMonthly();
        break;
      case 4:
        filterByYearly();
        break;
      default:
        filterByDaily();
        break;
    }
  };

  const filterByDaily = () => {
    const dataSet = JSON.parse(JSON.stringify(dataSeriesBydate));
    dataSet.forEach((obj) => {
      delete obj['week_number'];
    });

    setDataSeriesByFrequency(dataSet);
  };

  const filterByWeekly = () => {
    const dataSet = JSON.parse(JSON.stringify(dataSeriesBydate));
    const keys = Object.keys(dataSet[0]).filter(
      (value) => value !== 'date' && value !== 'week_number'
    );
    let count = 0;
    let index = 1;
    const newData = Object.values(
      dataSet.reduce((acc, obj) => {
        const week = obj.week_number;
        if (!acc[week]) {
          acc[week] = { date: `Week ${count + 1}` };
          keys.forEach((key) => {
            acc[week][key] = 0;
            if (acc[week - 1]) {
              const ave = acc[week - 1][key] / (index - 1);
              acc[week - 1][key] = ave.toFixed(2);
            }
          });
          count++;
          index = 1;
        }

        keys.forEach((key) => {
          acc[week][key] += obj[key];
        });
        index++;
        return acc;
      }, {})
    );

    setDataSeriesByFrequency(newData);
  };
  // const getWeekNo = (date) => {
  //   if (date >= 1 && date <= 7) {
  //     return 0;
  //   } else if (date >= 8 && date <= 14) {
  //     return 1;
  //   } else if (date >= 15 && date <= 21) {
  //     return 2;
  //   }
  //   if (date >= 22) {
  //     return 3;
  //   }
  // };
  // const filterByWeekly = () => {
  //   const dataSet = JSON.parse(JSON.stringify(dataSeriesBydate));
  //   const keys = Object.keys(dataSet[0]).filter((value) => value != 'date');

  //   const newData = Object.values(
  //     dataSet.reduce((acc, obj) => {
  //       const date = new Date(obj.date).getDate();
  //       const week = getWeekNo(date);
  //       // const day = new Date(obj.date).getDay();
  //       if (!acc[week]) {
  //         acc[week] = { date: weekList[week] };
  //         keys.forEach((key) => {
  //           acc[week][key] = 0;
  //         });
  //       }

  //       keys.forEach((key) => {
  //         acc[week][key] += obj[key];
  //       });
  //       return acc;
  //     }, {})
  //   );

  //   setDataSeriesByFrequency(newData);
  // };

  const filterByMonthly = () => {
    const dataSet = JSON.parse(JSON.stringify(dataSeriesBydate));
    const keys = Object.keys(dataSet[0]).filter(
      (value) => value !== 'date' && value !== 'week_number'
    );
    const newData = Object.values(
      dataSet.reduce((acc, obj) => {
        const month = new Date(obj.date).getMonth();
        if (!acc[month]) {
          acc[month] = { date: monthList[month] };
          keys.forEach((key) => {
            acc[month][key] = 0;
          });
        }

        keys.forEach((key) => {
          acc[month][key] += obj[key];
        });
        return acc;
      }, {})
    );

    setDataSeriesByFrequency(newData);
  };

  const filterByYearly = () => {
    const dataSet = JSON.parse(JSON.stringify(dataSeries));
    const keys = Object.keys(dataSet[0]).filter(
      (value) => value !== 'date' && value !== 'week_number'
    );
    const newData = Object.values(
      dataSet.reduce((acc, obj) => {
        const year = new Date(obj.date).getFullYear();
        if (!acc[year]) {
          acc[year] = { date: year };
          keys.forEach((key) => {
            acc[year][key] = 0;
          });
        }

        keys.forEach((key) => {
          acc[year][key] += obj[key];
        });
        return acc;
      }, {})
    );

    setDataSeriesByFrequency(newData);
  };

  const getData = async (start, end) => {
    setIsLoading(true);
    const property =
      selection == 'SR Type'
        ? 'srType'
        : selection == 'Field Agent'
        ? 'user'
        : '';
    try {
      var startD = new Date(start);
      var endD = new Date(end);
      startD.setDate(startD.getDate() + 1);
      endD.setDate(endD.getDate() + 1);
      const result = await getAvgTimeBarChart(
        startD.toISOString().slice(0, 10),
        endD.toISOString().slice(0, 10),
        property
      );
      if (result.status === 200) {
        setDataSeries(result.data);
        getSelectionData(result.data);
        setSelectedValue([]);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
    // setDataSeries(data);
    // getSelectionData(data);
  };

  useEffect(() => {
    getData(chartStartDate, chartEndDate);
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      getData(chartStartDate, chartEndDate);
      console.log('*******************');
      console.log('Updated the Chart!');
    }, 600000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setDataSeriesByDate(dataSeries);
  }, [dataSeries]);

  // const setupData = (series) => {
  //   const dataSet = [];
  //   const brushDataSet = [];
  //   series.forEach((el, id) => {
  //     if (!dataSet[id]) {
  //       dataSet[id] = {
  //         name: el.name,
  //       };
  //     }
  //     el.data.forEach((__el, __id) => {
  //       dataSet[id][__el.category] = __el.value;

  //       if (!brushDataSet[__id]) {
  //         brushDataSet[__id] = {
  //           name: __el.category,
  //         };
  //       }
  //       brushDataSet[__id][el.name] = __el.value;
  //     });
  //   });

  //   setLineChartData(dataSet);
  //   setBrushDataSeries(brushDataSet);
  // };

  const getMaxValue = () => {
    return (
      <div>
        {legendDataSelections.map((val, index) => (
          <Grid
            key={index}
            container
            style={{
              flexDirection: 'row',
              textAlign: 'left',
              marginBottom: '0.5rem',
            }}
          >
            <CheckBoxOutlineBlankIcon
              style={{
                width: '0.5rem',
                height: '0.6rem',
                marginRight: '0.5rem',
                color: getColor(val.name, index),
                backgroundColor: getColor(val.name, index),
              }}
            />
            <Typography
              style={{
                fontSize: '0.6rem',
                fontFamily: 'monospace',
                color: getColor(val.name, index),
                textAlign: 'left',
                fontWeight: 400,
              }}
            >
              {val.name}
            </Typography>
          </Grid>
        ))}
      </div>
    );
  };

  const DataFormater = (number) => {
    if (number >= 10000) {
      return number / 1000 + ' k';
    }
    return number;
  };

  const getColor = (name, index) => {
    if (name == 'Average') {
      return averageColor;
    }
    return colorArray[index % colorArray.length];
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.customToolTip}>
          <p
            style={{
              fontSize: '1rem',
              fontFamily: 'monospace',
              textAlign: 'center',
              marginBottom: '0.5rem',
            }}
          >
            {payload[0].payload.date}
          </p>
          {payload.map((option) => (
            <Grid
              container
              key={option.name}
              alignItems="center" 
              style={{ flexWrap: 'nowrap' , minWidth: '400px'}}
            >
              <Grid item xs={8} style={{ overflow: 'hidden' }}>
                <Typography
                  style={{
                    fontSize: '0.8rem',
                    fontFamily: 'monospace',
                    color: option.color,
                    textAlign: 'left',
                    fontWeight: 600,
                    whiteSpace: 'nowrap', 
                  }}
                >
                  {option.name}
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <Typography
                  style={{
                    fontSize: '0.8rem',
                    fontFamily: 'monospace',
                    color: option.color,
                    textAlign: 'right',
                    fontWeight: 600,
                    whiteSpace: 'nowrap', // Prevent text wrapping
                  }}
                >
                  {mapByScale(option.value)}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container className={classes.titleContiner}>
          <Grid item xs={12} sm={9} md={9}>
            <Typography className={classes.title}>
              {title.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} md={3} style={{ textAlign: 'right' }}>
            <Button size="small" className={classes.viewMoreButton}>
              View More
            </Button>
          </Grid>
        </Grid>
        {isLoading ? (
          <Skeleton
            className={classes.chartContainer}
            variant="rect"
            width={'100%'}
            height={'20rem'}
          />
        ) : (
          <Grid container>
            <Grid item xs={12} sm={9} md={9} className={classes.chartContainer}>
              <ResponsiveContainer width="100%" height="100%">
                {isLoading ? (
                  <></>
                ) : (
                  <BarChart
                    barCategoryGap={10}
                    barGap={3}
                    data={barChartData}
                    margin={{
                      top: 5,
                      right: 10,
                      left: -5,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      // interval={0}
                      dataKey="date"
                      tick={{ fontSize: 10, fontFamily: 'sans-serif' }}
                    />
                    <YAxis
                      tickFormatter={(value) => formatScale(value).toFixed(2)}
                      tick={{ fontSize: 10, fontFamily: 'sans-serif' }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Brush dataKey="date" height={30} stroke="#FA3916" />
                    {selectedValue.map((name, index) => {
                      return (
                        <Bar
                          dataKey={name}
                          key={index}
                          fill={colorArray[index]}
                          background={{ fill: '#eee' }}
                        />
                      );
                    })}
                  </BarChart>
                )}
              </ResponsiveContainer>
            </Grid>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              className={classes.LegendContainer}
            >
              {getMaxValue()}
            </Grid>
          </Grid>
        )}

          <Grid
            container
            className={classes.topContainer}
            spacing={1}
            alignItems="center"
            wrap="nowrap" 
          >
          <Grid item xs={3} style={{ minWidth: '180px', paddingRight: '5px' }}>
            <Typography className={classes.subTitle}>Select Date Range</Typography>
            <DateRange
              classes={classes}
              chartStartDate={chartStartDate}
              onChartDateChange={onChartDateChange}
              chartEndDate={chartEndDate}
              chartDataLoading={chartDataLoading}
            />
          </Grid>
          <Grid item xs={3} style={{ minWidth: '120px', paddingRight: '5px' }}>
            <Typography style={{ marginBottom: '0.2rem' }} className={classes.subTitle}>
              Frequency
            </Typography>
            <Frequency frequency={frequency} setFrequency={setFrequency} />
          </Grid>
          <Grid item xs={3} style={{ minWidth: '120px', paddingRight: '5px' }}>
            <Typography style={{ marginBottom: '0.2rem' }} className={classes.subTitle}>
              Scale
            </Typography>
            <TimeFrequency frequency={scale} setFrequency={setScale} />
          </Grid>
          <Grid item xs={3} style={{ minWidth: '150px' }}>
            <Typography style={{ marginBottom: '0.2rem' }} className={classes.subTitle}>
              {selection}
            </Typography>
            <Selection
              data={selectionData}
              setSelectedValue={setSelectedValue}
              selectedValue={selectedValue}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
