import React, { useContext, useEffect, useState } from 'react'
import { Box, CircularProgress } from '@material-ui/core';
import SidebarCheckBox from './SidebarCheckBox';
import JourneyCard from './JourneyCard';
import { RouteReportContext } from '../..';

const checkBoxTypes = {
    FULL: "FULL",
    PARTIAL: "PARTIAL",
    NONE: "NONE",
  };

const Journeys = () => {
    const { handleAllSelected, handleNoneSelected, travelRoutes, hasError, isLoading } = useContext(
        RouteReportContext
      );
    
      const [mainCheckType, setMainCheckType] = useState(checkBoxTypes.NONE);
    
      useEffect(() => {
        if (!travelRoutes) return;
    
        let checkCounts = 0;
        travelRoutes.forEach((travelRoute) => {
          if (travelRoute.isSelected) checkCounts++;
        });
    
        if (checkCounts === 0) setMainCheckType(checkBoxTypes.NONE);
        else if ((checkCounts === travelRoutes.length))
          setMainCheckType(checkBoxTypes.FULL);
        else setMainCheckType(checkBoxTypes.PARTIAL);
      }, [travelRoutes]);
    
      const mainCheckBoxHandler = () => {
        if (mainCheckType === checkBoxTypes.FULL) handleNoneSelected();
        else handleAllSelected();
      };

    return (
        <Box bgcolor="#EFEFEF" position="relative" boxShadow="0px 0px 10px 3px rgba(0,0,0,0.25)" borderRadius="10px" overflow="hidden">
          {isLoading && <CenterBox><CircularProgress /></CenterBox>}
          {hasError && <CenterBox>Unable to find journeys</CenterBox>}
          <Box sx={{"&::-webkit-scrollbar": {display:"none"}}} height="calc(100dvh - 250px)" p="25px" style={{ overflowY:"auto", scrollbarWidth:"0",transition:"all 0.25s ease-out", position:"relative" }}>
          <Box display="flex" height="100%" flexDirection="column" style={{gap:"20px"}}>
                <SidebarCheckBox 
                    checked={mainCheckType === checkBoxTypes.FULL}
                    intermediate={mainCheckType === checkBoxTypes.PARTIAL}
                    onChange={mainCheckBoxHandler}
                    label={"JOURNEYS"} 
                />
                      <Box display="flex"flexDirection="column" style={{gap: "10px", paddingBottom:"100px"}}>
                          {travelRoutes.map((travelRoute, key) => (
                              <JourneyCard key={key} travelRoute={travelRoute} />
                          ))}
                      </Box>
              </Box>
          </Box>
          <Box left={0} position="absolute" top={0} height="20px" width="100%" zIndex={10} sx={{background: "linear-gradient(to top, rgba(0,0,0,0),#EFEFEF)"}} />
          <Box left={0} position="absolute" bottom={0} height="50px" width="100%" zIndex={10} sx={{background: "linear-gradient(to bottom, rgba(0,0,0,0),#EFEFEF)"}} />
        </Box>
    )
}

const CenterBox = ({children}) => <Box  position="absolute" left={0} width="100%" height="100%" zIndex={10} sx={{color:"darkgray", fontSize:"15px", fontWeight:700}} display="flex" alignItems="center" justifyContent="center" >{children}</Box>

export default Journeys
