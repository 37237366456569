import React from "react";
import { Marker } from "@react-google-maps/api";

// converts svg icon into a marker
const CustomMarker = ({
  icon,
  iconSize,
  anchorPoint,
  position,
  labelText,
  labelColor,
  markerRef,
  onMouseOver,
  onMouseOut
}) => {
  // Convert the svg to a data URL
  const svgIcon = `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
    icon
  )}`;

  return (
    <Marker
      position={position}
      icon={{
        url: svgIcon,
        scaledSize: new window.google.maps.Size(iconSize, iconSize),
        anchor: anchorPoint
          ? new window.google.maps.Point(anchorPoint, anchorPoint)
          : null,
        labelOrigin: new window.google.maps.Point(
          iconSize / 2,
          (2 * iconSize) / 5
        ),
      }}
      options={{
        label: {
          text: labelText || " ",
          color: labelColor || "#000000",
          fontWeight: "800",
        },
      }}
      onLoad={(marker) => {
        if (markerRef) markerRef.current = marker;
      }}
      onMouseOver={(e) => {
        if (onMouseOver) onMouseOver(e);
      }}
      onMouseOut={(e) => {
        if (onMouseOut) onMouseOut(e);
      }} 
    />
  );
};

export default CustomMarker;
