import React, { useState, useEffect, useRef } from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import LoadingOverlay from 'react-loading-overlay';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import Spinner from 'components/Spinner/Spinner.js';

import ListAltOutlinedIcon from '@material-ui/icons/MessageOutlined';
import { ReportAndDashboardProperties ,getHighlightsForm } from 'Util/Property/ReportAndDashboardProperties';
import CardFooter from 'components/Card/CardFooter';
import Button from 'components/CustomButtons/Button.js';
import { Form, Field } from 'react-final-form';
import CardBody from 'components/Card/CardBody.js';
import generalProperties from 'Util/Property/GeneralProperties';
import { userProperties } from 'Util/Property/UserProperties';
import RepImages from '../../ReportsAndDashboard/img/repImages';
import { getAllUsers } from 'services/User/UserService';
import ColorSketch from 'components/ColorSketch/ColorSketch';
import IconSelector from 'components/IconSelector/IconSelector';
import SelectionList from 'components/Selections/SelectionList';
import DateRangeSelection from 'components/DateRanger/DateRange';
import DateRangeStyle from 'components/DateRanger/Style';
import { getServiceRequestCategoryCall } from 'services/ServiceRequest/serviceRequestCategoryServices';
import { notyDefault } from 'components/Noty/NotyCustom';
import { notyTypes } from 'components/Noty/NotyCustom';
import DropDownSelection from 'components/DropDown/DropDown';
import { getAllCustomers,  getCustomerByName , getCustomer  } from 'services/Customer/CustomerService';
import { getActiveCategoryList } from 'services/Product/ProductService';
import axios from '../../../../axios/axios-default';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import UrlProperties from 'Util/Property/UrlProperties';
import { getActiveBrandList } from 'services/Product/ProductBrandService';
import { productProperties } from 'Util/Property/ProductProperties';
import { getActiveModelList } from 'services/Product/ModelService';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { customerProperties } from '../../../../Util/Property/CustomerPorerties';
import { TextField } from '@material-ui/core';
import {
  getActiveWorkflowByTypeAndSrTypeId,
  createHighlightConfig,
  getConfigbyId,
  updateTileConfig,
} from 'services/ReportAndDashBoard/ReportAndDashboardServices';
import id from 'date-fns/esm/locale/id';
import styles from './ReportsAndDashboard.module.css';
import CustomInput from "components/CustomInput/CustomInput.js";

const useStyles = makeStyles(DateRangeStyle);

const ReportsAndDashboard = (props) => {
  const classes = useStyles();

  const [workflowHeading, setWorkflowHeading] = useState(
    'Reports and Dashboard'
  );
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const initialStartDate = new Date();
  initialStartDate.setMonth(initialStartDate.getMonth() - 1);
  const [isLoading, setIsLoading] = useState(false);
  const [stages, setStages] = useState([]);
  const [tileColor, setTileColor] = useState('#3A034F');
  const [categoryType, setCategoryType] = useState('type1');
  const [selectedIcon, setSelectedIcon] = useState('FaDochub');
  const [variables, setVariables] = useState(false);
  const [dataSource, setDataSource] = useState(false);
  const [SelectedstagesArr, setSelectedStagesArr] = useState([]);
  // const [chartStartDate, setChartStartDate] = React.useState(initialStartDate);
  // const [chartEndDate, setChartEndDate] = React.useState(new Date());
  const [serviceRequestCategories, setServiceRequestCategories] = useState([]);
  const [frequency, setFrequency] = React.useState('');
  const [frequencyArray, setFrequencyArray] = useState([]);
  const [jobCountTypeArray, setJobCountTypeArray] = useState([]);
  const [selectedJobCountType, setSelectedJobCountType] = useState('');
  const [customers, setCustomers] = useState([]);
  const [userRolesList, setUserRolesList] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState('');
  const [selectedSrType, setSelectedSrType] = useState('');
  const [selectedCustomerCat1, setSelectedCustomerCat1] = useState('');
  const [selectedCustomerCat2, setSelectedCustomerCat2] = useState('');
  const [selectedCustomerCat3, setSelectedCustomerCat3] = useState('');
  const [productCategories, setProductCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState('');
  const [selectedStgaeForDropDown, setSelectedStageForDropown] = useState('');
  const [selectedProductBrands, setSelectedProductBrands] = useState('');
  const [selectedProductModel, setSelectedProductModel] = useState('');
  const [selectedDataSource, setSelectedDataSource] = useState('');
  const [selectedSatisfactionType, setselectedSatisfactionType] = useState('');
  const [userRoleOptions, setUserRoleOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [catid, setCatId] = useState('');
  const [catidObj, setCatIdObj] = useState({});
  const [brandId, setBrandId] = useState('');
  const [brandIdObj, setBrandIdObj] = useState({});
  const [modelId, setModelId] = useState('');
  const [modelIdObj, setModelIdObj] = useState({});
  const [productsBrands, setProductBrands] = useState([]);
  const [productModels, setProductModels] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isFirstLoadBrand, setIsFirstLoadBrand] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedReportType, setSelectedReportType] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [
    districtsOfSelectedProvince,
    setdistrictsOfSelectedProvince,
  ] = useState([]);
  const [selectedExpenses, setSelectedExpenses] = useState('');
  const [selectedTransportMode, setSelectedTransportMode] = useState('');
  const [selectedAction, setSelectedAction] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [cusCat1Suggessions, setcusCat1Suggessions] = useState([]);
  // const [cat1Val, setCat1Val] =useState('');
  const [selectedcat1Val, setSelectedCat1Val] = useState('');
  const [cusCat2Suggessions, setcusCat2Suggessions] = useState([]);
  const [cat2Val, setCat2Val] = useState('');
  const [selectedcat2Val, setSelectedCat2Val] = useState('');
  const [cusCat3Suggessions, setcusCat3Suggessions] = useState([]);
  const [cat3Val, setCat3Val] = useState('');
  const [selectedcat3Val, setSelectedCat3Val] = useState('');
  const [actionsArrayList, setActionsArrayList] = useState([]);
  const [title, setTitle] = useState('');
  const [srTypeID, setSrTypeID] = useState('');
  const [srTypeIDObj, setSrTypeIDObj] = useState([]);
  const [employeeID, setEmployeeID] = useState('');
  const [stagesArrayResp, setStagesArrayResp] = useState([]);
  const [expanded, setExpanded] = React.useState('panel1');
  const [expanded2, setExpanded2] = React.useState('panel2');
  const [expanded3, setExpanded3] = React.useState('panel3');
  const [stageIds, setStageIds] = useState([]);
  const [roleIds, setRoleIds] = useState([]);
  const [customerIds, setCustomerIds] = useState([]);
  const [userRolesDef, setUserRolesDef] = useState([]);
  const [customersDef, setCustomersDef] = useState([]);
  const [stagesDef, setStagesDef] = useState([]);
  const [isExist, setIsExist] = useState(false);
  const [loadedDataId, setLoadedDataId] = useState('');
  const [valid, setValid] = useState(true);
  const [tileId, setTileId] = useState();
  const [sequence, setSequence] = useState();
  const [highlightsForm, setHighlightsForm] = useState(getHighlightsForm());
  const [initialState, setInitialState] = useState({
    customer_id: "",
  });

  const frequencyArrayEx = [
    { id: '1', name: 'Today' },
    { id: '2', name: 'Yesterday' },
    { id: '3', name: 'This Week' },
    { id: '4', name: 'Last Week' },
    { id: '5', name: 'Last 7 Days' },
    { id: '6', name: 'This Month' },
    { id: '7', name: 'Last Month' },
    { id: '8', name: 'Last 30 Days' },
  ];

  const jobCountTypes = [
    { id: '1', name: 'Current Stage' },
    { id: '2', name: 'Transition' },
  ];

  const userRoless = [
    { id: '1', name: 'Super Admin' },
    { id: '2', name: 'Role 2' },
    { id: '3', name: 'Role 3' },
  ];

  const List = [
    { id: '1', name: 'Assigned' },
    { id: '2', name: 'Customer Visit' },
    { id: '3', name: 'Complete Job' },
    { id: '4', name: 'Incomplete' },
    { id: '5', name: 'Others' },
  ];

  const expencesArr = [
    { id: '1', name: 'Travelling' },
    { id: '2', name: 'Meal' },
    { id: '3', name: 'Fixed' },
    { id: '4', name: 'Other' },
  ];

  const departmentArray = [
    { name: 'All', id: '-1' },
    { name: 'WAREHOUSE', id: '1' },
    { name: 'FINANCE', id: '2' },
    { name: 'IT', id: '3' },
    { name: 'POS', id: '4' },
    { name: 'SALES', id: '5' },
    { name: 'TECHNICAL', id: '6' },
  ];

  const reportsTypeArray = [
    { name: 'SR Type Wise', id: '1' },
    { name: 'Product Wise', id: '2' },
    { name: 'Employee Wise', id: '3' },
  ];

  const provinceArray = [
    { name: 'CENTRAL', id: '1' },
    { name: 'EASTERN', id: '2' },
    { name: 'NORTHERN', id: '3' },
    { name: 'NORTH_CENTRAL', id: '4' },
    { name: 'NORTH_WESTERN', id: '5' },
    { name: 'SABARAGAMUWA', id: '6' },
    { name: 'SOUTHERN', id: '7' },
    { name: 'UVA', id: '8' },
    { name: 'WESTERN', id: '9' },
  ];

  const expencesArray = [
    { name: 'All', id: '1' },
    { name: 'Travel', id: '2' },
    { name: 'BA/TA', id: '3' },
    { name: 'Meal', id: '4' },
    { name: 'Fixed', id: '5' },
  ];

  const transprtModeArray = [
    { name: 'All', id: '1' },
    { name: 'Bus', id: '2' },
    { name: 'Company Bike', id: '3' },
    { name: 'Private Bike', id: '4' },
    { name: 'Other', id: '5' },
  ];

  useEffect(() => {
    setFrequencyArray(frequencyArrayEx);
    setJobCountTypeArray(jobCountTypes);
    getAllUserRoles();
    getServiceRequestCategories();
    // getCustomers();
    getProductCategories();
    getEmployees();
    searchCategory1();
    searchCategory2();
    searchCategory3();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    let tileId = 0;
    if (props.id && props.id > 0) {
      if (props.isEdit) {
        setIsEdit(true);
      } else {
        setIsView(true);
      }
      tileId = props.id;
    } else if (props.location) {
      const query = new URLSearchParams(props.location.search);
      let queryParam = {};
      for (let param of query.entries()) {
        queryParam[param[0]] = param[1];
      }
      if (typeof queryParam['id'] != 'undefined') {
        tileId = queryParam['id'];
        setTileId(tileId);
        if (queryParam['isEdit'] === 'true') {
          setIsEdit(true);
        } else {
          setIsView(true);
        }
      }
    }
    if (tileId > 0) {
      getProductCategories();
      getBackgroundData(tileId);
      setIsExist(true);
    } else {
      setIsExist(false);
    }
    // let permissionArray = [PermissionProperties.viewProductAttribute, PermissionProperties.viewRoleAndPermissions];
    // setPermissions(checkPermissoinListAvailable(permissionArray));
  }, [props.id]);

  useEffect(() => {
    action();
    setStagesArrayResp([]);
    setSelectedSrType('');
    setSelectedCustomerCat1('');
    setSelectedCustomerCat2('');
    setSelectedCustomerCat3('');
    setSelectedStageForDropown('');
    setSelectedAction('');
  }, [categoryType]);

  useEffect(() => {
    setSelectedStagesArr([]);
    handleSelectedStageArr([]);
    if (selectedSrType.length !== 0 && srTypeID !== '') {
      getStages(srTypeID);
    }
  }, [selectedSrType]);

  useEffect(() => {
    let firstTime = isFirstLoad;
    getActiveBrandListForProduct(catid, firstTime);
  }, [selectedCategories]);

  useEffect(() => {
    let firstTime = isFirstLoadBrand;
    getActiveModelListForProduct(brandId, firstTime);
  }, [selectedProductBrands]);

  useEffect(() => {
    findDistricts(selectedProvince);
  }, [selectedProvince]);

  useEffect(() => {
    // if(isEdit !==true && isView !==true){
    if (
      (isEdit !== true && isView !== true) ||
      (isEdit === true && isView !== true)
    ) {
      getStagesNamesbyId(SelectedstagesArr);
    }
  }, [SelectedstagesArr]);

  useEffect(() => {
    getUserRolesbyId(selectedUserRoles);
  }, [selectedUserRoles]);

  useEffect(() => {
    getCustemesbyId(selectedCustomers);
  }, [selectedCustomers]);

  const getBackgroundData = async (id) => {
    setIsLoading(true);
    await getConfigbyId(id)
      .then(async (result) => {
        if (result.status === 200) {
          setSequence(result.data.sequence);
          setCategoryType(result.data.tile_type);
          setTileColor(result.data.color);
          setSelectedIcon(result.data.icon_ref);
          setTitle(result.data.heading);
          setSelectedReportType(result.data.first_report_type);
          setSelectedAction(result.data.action);

          if (result.data.stages.length !== 0) {
            setSelectedStageForDropown(result.data.stages[0].name);

            let stgArr = [];
            if (result.data.stages.length > 0) {
              result.data.stages.map((st) => {
                stgArr.push({ id: st.id });
              });
            }
            setStageIds(stgArr);
          }

          setLoadedDataId(result.data.id);
          setIsFirstLoad(true);
          setSelectedSrType(result.data.sr_category.name);
          setSrTypeID(result.data.sr_category.id);
          await getStages(result.data.sr_category.id);
          setSrTypeIDObj({ id: result.data.sr_category.id });

          if (result.data.hasOwnProperty('product_category')) {
            setSelectedCategories(result.data.product_category.name);
            setCatId(result.data.product_category.id);
            setCatIdObj({ id: result.data.product_category.id });
            if (result.data.product_category !== null) {
              await getActiveBrandListForProduct(
                result.data.product_category.id,
                false
              );
            }
          }
          if (result.data.hasOwnProperty('product_brand')) {
            setSelectedProductBrands(result.data.product_brand.name);
            setBrandId(result.data.product_brand.id);
            setBrandIdObj({ id: result.data.product_brand.id });
            if (result.data.product_brand.id !== null) {
              await getActiveModelListForProduct(
                result.data.product_brand.id,
                false
              );
            }
          }
          if (result.data.hasOwnProperty('product_model')) {
            setSelectedProductModel(result.data.product_model.name);
            setModelId(result.data.product_model.id);
            setModelIdObj({ id: result.data.product_model.id });
          }
          // setSelectedCategories(result.data.product_category.name);
          // setCatId(result.data.product_category.id);
          // setCatIdObj({'id':result.data.product_category.id});
          // if (result.data.product_category !== null){
          //     getActiveBrandListForProduct(result.data.product_category.id,false);
          // }

          // setSelectedProductBrands(result.data.product_brand.name);
          // setBrandId(result.data.product_brand.id);
          // setBrandIdObj({'id':result.data.product_brand.id});
          // if (result.data.product_brand.id !== null){
          //     getActiveModelListForProduct(result.data.product_brand.id,false);
          // }
          // setSelectedProductModel(result.data.product_model.name);
          // setModelId(result.data.product_model.id);
          // setModelIdObj({'id':result.data.product_model.id});
          setSelectedProvince(result.data.province);
          setSelectedDistrict(result.data.district);
          setSelectedDepartment(result.data.department);
          setSelectedExpenses(result.data.expense_type);
          setSelectedTransportMode(result.data.transport_medium);
          setSelectedCustomerCat1(result.data.customer_category1);
          setSelectedCustomerCat2(result.data.customer_category2);
          setSelectedCustomerCat3(result.data.customer_category3);
          if (result.data.action=="Job Details"){
            if(result.data.hasOwnProperty('job_count_type')){
              setSelectedJobCountType(result.data.job_count_type);
            }else{
              setSelectedJobCountType(jobCountTypes[0].name);
            }
          }

          let listRoles = [];
          if (result.data.roles.length > 0) {
            result.data.roles.map((role) => {
              listRoles.push(role.name);
            });
          }
          setUserRolesDef(listRoles);

          let listCustomers = [];
          if (result.data.customers.length > 0) {
            result.data.customers.map((cus) => {
              listCustomers.push(cus.name);
            });
            setCustomerValue(result.data.customers)
          }
          setCustomersDef(listCustomers);

          let listStages = [];
          if (result.data.stages.length > 0) {
            result.data.stages.map((stg) => {
              listStages.push(stg.name);
            });
          }
          setStagesDef(listStages);
          setFrequency(result.data.period);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: ReportAndDashboardProperties.messages.error.loadData,
          });
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: ReportAndDashboardProperties.messages.error.loadData,
        });
      });
  };

  const getAllUserRoles = async () => {
    setIsLoading(true);
    await axios
      .get(UrlProperties.user.getAllRoles)
      .then((result) => {
        if (result.status === 200) {
          let userRoleOptions = [];
          result.data.map((role) =>
            userRoleOptions.push({ id: role.id, name: role.name })
          );
          setUserRolesList(userRoleOptions);
          setUserRoleOptions(userRoleOptions);
          setIsLoading(false);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: result.data
              ? result.data.message
              : userProperties.messages.error.userRoles,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: userProperties.messages.error.userRoles,
        });
      });
  };

  const getServiceRequestCategories = async () => {
    setIsLoading(true);
    await getServiceRequestCategoryCall()
      .then((result) => {
        if (result.success === true) {
          setServiceRequestCategories(result.data);
          setIsLoading(false);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text:
              ReportAndDashboardProperties.messages.error.serviceRequesstTypes,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text:
            ReportAndDashboardProperties.messages.error.serviceRequesstTypes,
        });
      });
  };

  const getStages = async (srID) => {
    setIsLoading(true);
    await getActiveWorkflowByTypeAndSrTypeId(srID)
      .then((result) => {
        let StagesList = result.data.stages_list;
        let stagesArray = [];
        StagesList.map((stg) => {
          stagesArray.push({
            id: stg.id,
            name: stg.stage_name,
            stageId: stg.stage_id,
          });
        });
        setStagesArrayResp(stagesArray);
        if (categoryType === 'type2') {
          setSelectedStageForDropown(result.data.stages_list[0].stage_name);
          let stageidFromDeopDown = [];
          stageidFromDeopDown.push(result.data.stages_list[0].stage_name);
          setSelectedStagesArr(stageidFromDeopDown);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: ReportAndDashboardProperties.messages.error.stages,
        });
      });
  };

  const getCustomers = async () => {
    setIsLoading(true);
    await getAllCustomers()
      .then((result) => {
        if (result.status === 200) {
          let customersList = result.data;
          let customersArray = [];
          customersList.map((cus) => {
            customersArray.push({ id: cus.id, name: cus.name });
          });
          setCustomers(customersArray);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: ReportAndDashboardProperties.messages.error.categories,
          });
        }
      })
      .catch((e) => {
        setCustomers([]);
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: ReportAndDashboardProperties.messages.error.categories,
        });
      });
  };

  const getEmployees = async () => {
    setIsLoading(true);
    await getAllUsers()
      .then((result) => {
        if (result.status === 200) {
          let userOptions = [];
          userOptions.push({ id: -1, name: 'All' });
          result.data.map((user) =>
            userOptions.push({
              id: user.id,
              name: user.first_name + ' ' + user.last_name,
            })
          );
          setEmployees(userOptions);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: ReportAndDashboardProperties.messages.error.users,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: ReportAndDashboardProperties.messages.error.users,
        });
      });
  };

  const getProductCategories = async () => {
    setIsLoading(true);
    await getActiveCategoryList()
      .then((result) => {
        if (result.status === 200) {
          let categoryOptions = [];
          categoryOptions.push({ id: -1, name: 'All' });
          result.data.map((category) =>
            categoryOptions.push({ id: category.id, name: category.name })
          );
          setIsLoading(false);
          setProductCategories(categoryOptions);
          setIsFirstLoad(false);
        } else {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: ReportAndDashboardProperties.messages.error.categories,
          });
        }

        const query = new URLSearchParams(props.location.search);
        let queryParam = {};
        for (let param of query.entries()) {
          queryParam[param[0]] = param[1];
        }
        if (typeof queryParam['id'] === 'undefined') {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notyDefault({
          type: notyTypes.ERROR,
          text: ReportAndDashboardProperties.messages.error.categories,
        });
      });
  };

  const getActiveBrandListForProduct = async (catId, isFirstTime) => {
    setIsLoading(true);
    if (isFirstTime === false) {
      await getActiveBrandList(catId)
        .then((result) => {
          if (result.status === 200) {
            let brandOptions = [];
            brandOptions.push({ id: -1, name: 'All' });
            result.data.map((brand) =>
              brandOptions.push({ id: brand.id, name: brand.name })
            );
            setProductBrands(brandOptions);
            setIsFirstLoadBrand(false);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : productProperties.messages.error.loadProductBrands,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: productProperties.messages.error.loadProductBrands,
          });
        });
    }
  };

  const getActiveModelListForProduct = async (brandId, isFirstTime) => {
    setIsLoading(true);
    if (isFirstTime === false) {
      await getActiveModelList(brandId)
        .then((result) => {
          if (result.status === 200) {
            let modelOptions = [];
            modelOptions.push({ id: -1, name: 'All' });
            result.data.map((model) =>
              modelOptions.push({ id: model.id, name: model.name })
            );
            setProductModels(modelOptions);
            setIsLoading(false);
          } else {
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : productProperties.messages.error.loadProductModels,
            });
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: productProperties.messages.error.loadProductModels,
          });
        });
    }
  };

  const searchCategory1 = async (inputVal, inputProps) => {
    setIsLoading(true);
    await axios
      .get('/getCategory1list')
      .then((result) => {
        if (result.status == '200') {
          let cat1Options = [];
          cat1Options.push({ name: 'All' });
          result.data.map((category) => cat1Options.push({ name: category }));
          setIsLoading(false);
          setcusCat1Suggessions(cat1Options);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: customerProperties.messages.error.loadData,
          });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: customerProperties.messages.error.loadData,
        });
      });
  };

  const searchCategory2 = async (inputVal, inputProps) => {
    setIsLoading(true);
    await axios
      .get('/getCategory2list')
      .then((result) => {
        if (result.status == '200') {
          let cat2Options = [];
          cat2Options.push({ name: 'All' });
          result.data.map((category) => cat2Options.push({ name: category }));
          setIsLoading(false);
          setcusCat2Suggessions(cat2Options);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: customerProperties.messages.error.loadData,
          });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: customerProperties.messages.error.loadData,
        });
      });
  };

  const searchCategory3 = async (inputVal, inputProps) => {
    setIsLoading(true);
    await axios
      .get('/getCategory3list')
      .then((result) => {
        if (result.status == '200') {
          let cat3Options = [];
          cat3Options.push({ name: 'All' });
          result.data.map((category) => cat3Options.push({ name: category }));
          setIsLoading(false);
          setcusCat3Suggessions(cat3Options);
        } else {
          notyDefault({
            type: notyTypes.ERROR,
            text: customerProperties.messages.error.loadData,
          });
        }
      })
      .catch((error) => {
        notyDefault({
          type: notyTypes.ERROR,
          text: customerProperties.messages.error.loadData,
        });
      });
  };

  const action = () => {
    let ActionsArray = [{}];
    if (categoryType === 'type1') {
      ActionsArray = [
        { name: 'Staff Activities', id: '1' },
        { name: 'Job Details', id: '2' },
        { name: 'Field Expenses', id: '3' },
        { name: 'Job Time', id: '4' },
      ];
    }
    if (categoryType === 'type2') {
      ActionsArray = [
        { name: 'Customer Satisfaction', id: '1' },
        { name: 'Employee Satisfaction', id: '2' },
      ];
    }
    if (categoryType === 'type3') {
      ActionsArray = [
        { name: 'Count Base', id: '1' },
        { name: 'Cost Base', id: '2' },
        { name: 'Time Base', id: '3' },
      ];
    }
    if (categoryType === 'type4') {
      ActionsArray = [
        { name: 'Average Count ', id: '1' },
        { name: 'Average Cost', id: '2' },
        { name: 'Average Time', id: '3' },
      ];
    }
    setActionsArrayList(ActionsArray);
  };

  const findDistricts = (province) => {
    let districtsArray = [{}];
    if (province === 'WESTERN') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'COLOMBO', id: '1' },
        { name: 'GAMPAHA', id: '2' },
        { name: 'KALUTARA', id: '3' },
      ];
    }
    if (province === 'NORTHERN') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'JAFNA', id: '1' },
        { name: 'KILINOCHCHI', id: '2' },
        { name: 'MANNAR', id: '3' },
        { name: 'MULLAITIVU', id: '4' },
        { name: 'VAVUNIYA', id: '5' },
      ];
    }
    if (province === 'CENTRAL') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'MATALE', id: '1' },
        { name: 'KANDY', id: '2' },
        { name: 'NUWARA_ELIYA', id: '3' },
      ];
    }
    if (province === 'EASTERN') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'TRINCOMALEE', id: '1' },
        { name: 'BATTICOLOA', id: '2' },
        { name: 'AMAPARA', id: '3' },
      ];
    }
    if (province === 'NORTH_CENTRAL') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'ANURADHAPURA', id: '1' },
        { name: 'POLONNARUWA', id: '2' },
      ];
    }
    if (province === 'NORTH_WESTERN') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'KURUNAGALA', id: '1' },
        { name: 'PUTTALAM', id: '2' },
      ];
    }
    if (province === 'SABARAGAMUWA') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'KEGALLE', id: '1' },
        { name: 'RATHANAPURA', id: '2' },
      ];
    }
    if (province === 'SOUTHERN') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'GALLE', id: '1' },
        { name: 'MATARA', id: '2' },
        { name: 'HAMBANTOTA', id: '3' },
      ];
    }
    if (province === 'UVA') {
      districtsArray = [
        { name: 'All', id: '-1' },
        { name: 'BADULLA', id: '1' },
        { name: 'MONARAGALA', id: '2' },
      ];
    }
    setdistrictsOfSelectedProvince(districtsArray);
  };

  const findCatId = (catName) => {
    productCategories.map((cat) => {
      if (cat.name === catName) {
        setCatId(cat.id);
        setCatIdObj({ id: cat.id });
      }
    });
  };

  const findBranId = (brandName) => {
    productsBrands.map((brand) => {
      if (brand.name === brandName) {
        setBrandId(brand.id);
        setBrandIdObj({ id: brand.id });
      }
    });
  };

  const findModelId = (modelName) => {
    productModels.map((model) => {
      if (model.name === modelName) {
        setModelId(model.id);
        setModelIdObj({ id: model.id });
      }
    });
  };

  const getSelectedSRTypeID = (srType) => {
    serviceRequestCategories.map((type) => {
      if (type.name === srType) {
        setSrTypeID(type.id);
        setSrTypeIDObj({ id: type.id });
      }
    });
  };

  const getEmployeeID = (employee) => {
    employees.map((emp) => {
      if (emp.name === employee) {
        setEmployeeID(emp.id);
      }
    });
  };

  useEffect(() => {
    // console.log("*********eeeeeeeeeeeeeee******",roleIds.length)
    // console.log("*********2****************",title.length)
    // console.log("**********3****************",selectedReportType.length)
    // console.log("***********4***************",selectedAction.length)
    // console.log("***********5***************",frequency.length)
    if (
      roleIds.length === 0 ||
      title.length === 0 ||
      selectedReportType.length === 0 ||
      selectedAction.length === 0 ||
      frequency.length === 0 ||
      srTypeIDObj.length === 0
    ) {
      setValid(false);
    } else {
      setValid(true);
    }
  });

  const TileConfigSubmit = async (event) => {
    let ReportDashboardFormData = {};
    let data = {};
    data['tile_type'] = categoryType;
    data['color'] = tileColor;
    data['icon_ref'] = selectedIcon;
    data['heading'] = title;
    data['first_report_type'] = selectedReportType;
    data['action'] = selectedAction;
    data['job_count_type'] = selectedJobCountType;
    data['roles'] = roleIds;
    data['sr_category'] = srTypeIDObj;
    data['stages'] = stageIds;
    data['period'] = frequency;
    data['department'] = selectedDepartment;
    data['customers'] = customerIds;
    data['province'] = selectedProvince;
    data['district'] = selectedDistrict;
    data['customer_category1'] = selectedCustomerCat1;
    data['customer_category2'] = selectedCustomerCat2;
    data['customer_category3'] = selectedCustomerCat3;
    data['product_category'] = catidObj;
    data['product_brand'] = brandIdObj;
    data['product_model'] = modelIdObj;
    data['expense_type'] = selectedExpenses;
    data['transport_medium'] = selectedTransportMode;
    data['sequence'] = sequence;

    // console.log("%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%",data)

    if (valid === true) {
      setIsLoading(true);

      if (isExist === true) {
        await updateTileConfig(data, loadedDataId)
          .then((result) => {
            if (typeof result !== 'undefined') {
              if (result.status === 200) {
                notyDefault({
                  type: notyTypes.SUCCESS,
                  text: ReportAndDashboardProperties.messages.success.update,
                });
                props.history.goBack();
              } else {
                notyDefault({
                  type: notyTypes.ERROR,
                  text: ReportAndDashboardProperties.messages.error.update,
                });
              }
            }
            setIsLoading(false);
          })
          .catch((error) => {
            notyDefault({
              type: notyTypes.ERROR,
              text: ReportAndDashboardProperties.messages.error.createNew,
            });
            setIsLoading(false);
          });
      } else {
        await createHighlightConfig(data)
          .then((result) => {
            if (typeof result !== 'undefined') {
              if (result.status === 200) {
                notyDefault({
                  type: notyTypes.SUCCESS,
                  text: ReportAndDashboardProperties.messages.success.createNew,
                });
                props.history.goBack();
              } else {
                notyDefault({
                  type: notyTypes.ERROR,
                  text: ReportAndDashboardProperties.messages.error.createNew,
                });
              }
            }
            setIsLoading(false);
          })
          .catch((error) => {
            notyDefault({
              type: notyTypes.ERROR,
              text: ReportAndDashboardProperties.messages.error.createNew,
            });
            setIsLoading(false);
          });
      }

      setIsLoading(false);
    }
  };

  // const onSelectcat1 =(Cat1event) =>{
  //     setSelectedCat1Val(Cat1event);
  //  }

  // const onSelectcat2 =(Cat2event) =>{
  //     setSelectedCat2Val(Cat2event);
  //  }

  // const onSelectcat3 =(Cat3event) =>{
  //     setSelectedCat3Val(Cat3event);
  //  }

  // const handlSelectedRole = (role ) =>{
  //     setSelectedRole(role);
  //  }

  const handleColor = (colorCode) => {
    setTileColor(colorCode);
  };
  const handlecatoegory = (categoryType) => {
    setCategoryType(categoryType);
  };

  const handleIconImage = (iconImage) => {
    setSelectedIcon(iconImage);
  };

  const handleSelectedStageArr = (stages) => {
    // console.log("********************************",stages)
    setSelectedStagesArr(stages);
  };

  const handleSelectedStageDropDown = (stage) => {
    setSelectedStageForDropown(stage);
    let stageidFromDeopDown = [];
    stageidFromDeopDown.push(stage);
    setSelectedStagesArr(stageidFromDeopDown);
    // getStagesNamesbyId(stage)
  };

  const handleTitle = (val) => {
    setTitle(val);
  };

  const handleFrequency = (frequency) => {
    setFrequency(frequency);
  };

  const handleSelectedCustomers = (customers) => {
    setSelectedCustomers(customers);
  };

  const handleSelectedUserRoles = (roles) => {
    setSelectedUserRoles(roles);
  };

  const handleSelectedCategories = (categories) => {
    setSelectedCategories(categories);
    findCatId(categories);
  };

  const handleSelectedProductBrand = (brand) => {
    setSelectedProductBrands(brand);
    findBranId(brand);
  };

  const handleSelectedProductModel = (model) => {
    setSelectedProductModel(model);
    findModelId(model);
  };

  const handlesrType = (type) => {
    setSelectedSrType(type);
    getSelectedSRTypeID(type);
  };

  const handleCategory1 = (cat1) => {
    setSelectedCustomerCat1(cat1);
  };

  const handleCategory2 = (cat2) => {
    setSelectedCustomerCat2(cat2);
  };

  const handleCategory3 = (cat3) => {
    setSelectedCustomerCat3(cat3);
  };

  const handleDepartment = (department) => {
    setSelectedDepartment(department);
  };

  // const handleSelectedEmployee = (employee) =>{
  //     setSelectedEmployees(employee);
  //     getEmployeeID(employee);
  // }

  const handleReportType = (type) => {
    setSelectedReportType(type);
  };

  const handleProvince = (province) => {
    setSelectedProvince(province);
  };

  const handleDistrict = (dist) => {
    setSelectedDistrict(dist);
  };

  const handleCatType1 = () => {
    setVariables(true);
    setDataSource(true);
  };
  const handleCatType2 = () => {
    setVariables(false);
    setDataSource(true);
  };

  const handleCatType3 = () => {
    setVariables(true);
    setDataSource(true);
  };

  const handleCatType4 = () => {
    setVariables(false);
    setDataSource(true);
  };

  const handleExpenses = (expences) => {
    setSelectedExpenses(expences);
  };

  const handleTransportMode = (mode) => {
    setSelectedTransportMode(mode);
  };

  const handleAction = (action) => {
    setSelectedAction(action);
    if(action !== 'Job Details'){
      setSelectedJobCountType('');
    }
  };

  const handleJobCountType = (type) => {
    setSelectedJobCountType(type);
  };

  // const requiredFields = [
  //     selectedUserRoles,
  //     selectedAction
  //     ];

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChangeAccordion2 = (panel2) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel2 : false);
  };

  const handleChangeAccordion3 = (panel3) => (event, newExpanded) => {
    setExpanded3(newExpanded ? panel3 : false);
  };

  const getUserRolesbyId = (list) => {
    let roleIds = [];
    if (list.length !== 0) {
      list.map((role) => {
        if (userRolesList.length !== 0) {
          userRolesList.map((roles) => {
            if (role === roles.name) {
              roleIds.push({ id: roles.id });
            }
          });
        }
      });
    }
    setRoleIds(roleIds);
  };

  const getStagesNamesbyId = (list) => {
    let stgIds = [];
    if (list.length !== 0) {
      list.map((stg) => {
        if (stagesArrayResp.length !== 0) {
          stagesArrayResp.map((stag) => {
            if (stg === stag.name) {
              stgIds.push({ id: stag.stageId });
            }
          });
        }
      });
    }
    setStageIds(stgIds);
  };

  const getCustemesbyId = (list) => {
    let cusIds = [];
    if (list.length !== 0) {
      list.map((cust) => {
        if (customers.length !== 0) {
          customers.map((cus) => {
            if (cust === cus.name) {
              cusIds.push({ id: cus.id });
            }
          });
        }
      });
    }
    setCustomerIds(cusIds);
  };

  const setCustomerValue = (val) => {
    let customerOptions = [];
    val.map((customer) =>
      customerOptions.push({
        value: customer.id,
        displayValue: customer.name,
      })
    );
    setDataToHighlightsForm("customer_id",customerOptions,true,null );

    let value = val[0].id;
    let elId= "customer_id"
    let formData = { ...highlightsForm }
    let elementData = formData[elId];
    let inputProps = { ...elementData.inputProps }; 
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setHighlightsForm(formData);

    let cusIds = []
    cusIds.push({id:value})
    setCustomerIds(cusIds)
  }

  let formElementArray = [];
  for (let key in highlightsForm) {
    formElementArray.push({
      id: key,
      config: highlightsForm[key],
    });
  }

  const setDataToHighlightsForm = (
    key,
    options,
    isFirstTime,
    optionValue
  ) => {
    let formData = highlightsForm[key];
    let inputProps = { ...formData.inputProps };
    inputProps.options = options;
    let defaultValue;

    if (isFirstTime && inputProps.initvalue !== "") {
      defaultValue = inputProps.initvalue;
    } else if (optionValue !== undefined) {
      defaultValue = optionValue;
    } else {
      defaultValue = options.length > 0 ? options[0].value : "";
    }
    inputProps.value = defaultValue;
    if (isFirstTime) {
      inputProps.initvalue = defaultValue;
    }
    formData.inputProps = inputProps;
    highlightsForm[key] = formData;
    return defaultValue;
  };

  const onChangeHandeler = async (event, elId, meta) => {
    let value= event.target.value;
    let formData = { ...highlightsForm };
    let elementData = formData[elId];
    let inputProps = { ...elementData.inputProps }; 
    inputProps.value = value;
    elementData.inputProps = inputProps;
    formData[elId] = elementData;
    setHighlightsForm(formData);
    if (elId === "customer_id") {
      let cusIds = []
      cusIds.push({id:value})
      setCustomerIds(cusIds)
    }
  };

  function fieldBuilder(element) {
    return (
      <Field name={element.id}>
        {({ input, meta, options, value }) => (
          <div style={{ position: "relative" }}>
            <CustomInput
              labelText={element.config.label}
              id={element.id}
              inputProps={{
                ...input,
                ...element.config.inputProps,
                readOnly: element.config.readOnly || element.config.doDisable ? true : false,
              }}
              type={element.config.elType}
              formControlProps={element.config.formControlProps}
              adornmentText={element.config.adornmentText}
              adornmentPosition={element.config.adornmentPosition}
              changed={(event, value) => {
                input.onChange(event);
                onChangeHandeler(event, element.id, meta);
              }}
              onSelectInputChange={(event) => {
                element.config.inputProps.tempValue = event.target.value;
                if (event && (event.key === "Enter" || event.keyCode === 13)) {
                  if (element.id === "customer_id") {
                    searchCustomer(element);
                  } 
                }
              }}
              onAdornmentClick={
                element.id === "customer_id"
                  ? () => {
                      searchCustomer(element);
                    } 
                  : undefined
              }
              multiple={element.config.multiple}
              labelProps={{
                ...element.config.labelProps,
                error: meta.error && meta.touched,
              }}
            />
            {meta.error && meta.touched && (
              <span className={styles.formError}>{meta.error}</span>
            )}
          </div>
        )}
      </Field>
    );
  }

  const searchCustomer = (element) => {
    const value = element.config.inputProps.tempValue;
    if (value.length > 0) {
      setIsLoading(true);
      // clearForm();
      getCustomerByName(value)
        .then((result) => {
          // asyncDataState.customer_id = true;
          if (result.status === 200) {
            if (result.data.length > 0) {
              let customerOptions = [];
              result.data.map((customer) =>
                customerOptions.push({
                  value: customer.id,
                  displayValue: customer.name + " " + `(${customer.address})`,
                })
              );
              let defaultVal = setDataToHighlightsForm(
                "customer_id",
                customerOptions,
                true,
                null
              );
            }
            setIsLoading(false);
          } else {
            // clearForm();
            setIsLoading(false);
            notyDefault({
              type: notyTypes.ERROR,
              text: result.data
                ? result.data.message
                : customerProperties.messages.error.loadCustomers,
            });
          }
        })
        .catch((error) => {
          // clearForm();
          setIsLoading(false);
          notyDefault({
            type: notyTypes.ERROR,
            text: customerProperties.messages.error.loadCustomers,
          });
        });
      element.config.inputProps.tempValue = "";
    }
  };

  return (
    <div>
      <LoadingOverlay
        active={isLoading}
        spinner={<Spinner />}
        text="Loading ..."
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div className="generic-form-container">
                {!isEdit ? (
                  <div className={'generic-form-header-wrapper'}>
                    <div className={'generic-page-title'}>
                      <span className={'generic-page-title-icon'}>
                        <ListAltOutlinedIcon />
                      </span>
                      <Typography
                        className={'generic-header-text'}
                        variant="h6"
                        style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                      >
                        {workflowHeading}
                      </Typography>
                      {/* <Typography variant="body1">Reports and Dashboard Configurations</Typography> */}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                <RepImages
                  getSelectedType={handlecatoegory}
                  selectedType={categoryType}
                  catchChange={categoryType}
                />

                <GridContainer>
                  <GridItem
                    style={{ marginLeft: '1.5rem', marginTop: '2rem' }}
                    xs={12}
                    sm={12}
                    md={1}
                  >
                    <Typography fontWeight="bold" variant="body1">
                      Tile Color
                    </Typography>
                  </GridItem>
                  <GridItem
                    style={{ marginLeft: '1rem', marginTop: '2rem' }}
                    xs={12}
                    sm={12}
                    md={2}
                  >
                    <ColorSketch
                      parentCallback={handleColor}
                      colorVal={tileColor}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem
                    style={{ marginLeft: '1.5rem', marginTop: '2rem' }}
                    xs={12}
                    sm={12}
                    md={1}
                  >
                    <Typography variant="body1">Tile Icon</Typography>
                  </GridItem>
                  <GridItem
                    style={{ marginLeft: '1rem', marginTop: '2rem' }}
                    xs={12}
                    sm={12}
                    md={2}
                  >
                    <IconSelector
                      IconImage={handleIconImage}
                      TileColor={tileColor}
                      Icon={selectedIcon}
                      loc={'tileConfig'}
                    />
                  </GridItem>
                </GridContainer>

                <Form
                  onSubmit={TileConfigSubmit}
                  validate={(values, ss) => {
                    const errors = {};

                    if (stages.length === 0) {
                      errors.stages = generalProperties.emptyField;
                    }
                    return errors;
                  }}
                  render={({
                    handleSubmit,
                    reset,
                    submitting,
                    pristine,
                    valid,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <CardBody>
                        {/* <DateRangeSelection
                                                classes={classes}
                                                chartStartDate={chartStartDate}
                                                onChartDateChange={onChartDateChange}
                                                chartEndDate={chartEndDate}
                                                chartDataLoading={chartDataLoading}
                                            /> */}

                        <Accordion
                          square
                          expanded={expanded === 'panel1'}
                          fullWidth={true}
                          style={{
                            backgroundColor: '#efefef',
                            color: '000',
                            borderRadius: '5px',
                          }}
                          onChange={handleChangeAccordion('panel1')}
                        >
                          <AccordionSummary
                            style={{
                              backgroundColor: '#e3e3e3',
                              borderRadius: '5px',
                            }}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography fontWeight={'bold'}>
                              Main Selections
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GridContainer xs={12} sm={12} md={12}>
                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <SelectionList
                                  lable={'User Role'}
                                  data={userRolesList}
                                  selectedDef={userRolesDef}
                                  setSelectedValue={handleSelectedUserRoles}
                                  selectedValue={handleSelectedUserRoles}
                                  isRequired={true}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <TextField
                                  required={true}
                                  fullWidth={true}
                                  id="Title"
                                  value={title}
                                  label="Tile Heading"
                                  onChange={(event) =>
                                    handleTitle(event.target.value)
                                  }
                                  autoComplete="off"
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'First Report Type'}
                                  dropDownData={reportsTypeArray}
                                  frequency={selectedReportType}
                                  setFrequency={handleReportType}
                                  isRequired={true}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Action'}
                                  dropDownData={actionsArrayList}
                                  frequency={selectedAction}
                                  setFrequency={handleAction}
                                  isRequired={true}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Date Range'}
                                  dropDownData={frequencyArray}
                                  frequency={frequency}
                                  setFrequency={handleFrequency}
                                  isRequired={true}
                                />
                              </GridItem>

                              {selectedAction && selectedAction == "Job Details" && <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Job Count Type'}
                                  dropDownData={jobCountTypeArray}
                                  frequency={selectedJobCountType}
                                  setFrequency={handleJobCountType}
                                  isRequired={true}
                                />
                              </GridItem>}
                            </GridContainer>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          square
                          expanded={expanded2 === 'panel2'}
                          fullWidth={true}
                          style={{
                            backgroundColor: '#efefef',
                            color: '000',
                            borderRadius: '5px',
                            marginTop: '1rem',
                          }}
                          onChange={handleChangeAccordion2('panel2')}
                        >
                          <AccordionSummary
                            style={{
                              backgroundColor: '#e3e3e3',
                              borderRadius: '5px',
                            }}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography fontWeight={'bold'}>
                              Service Request Selection
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GridContainer xs={12} sm={12} md={12}>
                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Service Request Type'}
                                  dropDownData={serviceRequestCategories}
                                  frequency={selectedSrType}
                                  setFrequency={handlesrType}
                                  isRequired={true}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                {categoryType === 'type1' ? (
                                  <SelectionList
                                    lable={'Stage'}
                                    data={stagesArrayResp}
                                    selectedDef={stagesDef}
                                    setSelectedValue={setSelectedStagesArr}
                                    selectedValue={handleSelectedStageArr}
                                    catchChange={selectedSrType}
                                  />
                                ) : categoryType === 'type2' ? (
                                  <DropDownSelection
                                    labelforDropDwon={'Stage'}
                                    dropDownData={stagesArrayResp}
                                    frequency={selectedStgaeForDropDown}
                                    setFrequency={handleSelectedStageDropDown}
                                    disabledVal={true}
                                  />
                                ) : (
                                  <DropDownSelection
                                    labelforDropDwon={'Stage'}
                                    dropDownData={stagesArrayResp}
                                    frequency={selectedStgaeForDropDown}
                                    setFrequency={handleSelectedStageDropDown}
                                    disabledVal={false}
                                  />
                                )}
                              </GridItem>
                            </GridContainer>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          square
                          expanded={expanded3 === 'panel3'}
                          fullWidth={true}
                          style={{
                            backgroundColor: '#efefef',
                            color: '000',
                            borderRadius: '5px',
                            marginTop: '1rem',
                          }}
                          onChange={handleChangeAccordion3('panel3')}
                        >
                          <AccordionSummary
                            style={{
                              backgroundColor: '#e3e3e3',
                              borderRadius: '5px',
                            }}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography fontWeight={'bold'}>
                              Sub Selections
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <GridContainer xs={12} sm={12} md={12}>
                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Product Category'}
                                  dropDownData={productCategories}
                                  frequency={selectedCategories}
                                  setFrequency={handleSelectedCategories}
                                />
                              </GridItem>
                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Product Brand'}
                                  dropDownData={productsBrands}
                                  frequency={selectedProductBrands}
                                  setFrequency={handleSelectedProductBrand}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Product Model'}
                                  dropDownData={productModels}
                                  frequency={selectedProductModel}
                                  setFrequency={handleSelectedProductModel}
                                />
                              </GridItem>

                              {/* <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <SelectionList
                                  lable={'Customer'}
                                  data={customers}
                                  selectedDef={customersDef}
                                  setSelectedValue={handleSelectedCustomers}
                                  selectedValue={handleSelectedCustomers}
                                />
                              </GridItem> */}

                              {formElementArray.map((element) => {
                                if (!element.config.isHide) {
                                  let mdVal = element.config.size;
                                  return (
                                    <React.Fragment key={element.id + "_frag"}>
                                      <GridItem
                                        style={{
                                          marginLeft: '0.0rem',
                                          marginTop: '1rem',
                                        }}
                                        key={element.id}
                                        xs={12}
                                        sm={12}
                                        md={mdVal}
                                      >
                                        {fieldBuilder(element)}
                                      </GridItem>
                                    </React.Fragment>
                                  );
                                }
                              })}

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Province'}
                                  dropDownData={provinceArray}
                                  frequency={selectedProvince}
                                  setFrequency={handleProvince}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'District'}
                                  dropDownData={districtsOfSelectedProvince}
                                  frequency={selectedDistrict}
                                  setFrequency={handleDistrict}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Customer Category 1'}
                                  dropDownData={cusCat1Suggessions}
                                  frequency={selectedCustomerCat1}
                                  setFrequency={handleCategory1}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Customer Category 2'}
                                  dropDownData={cusCat2Suggessions}
                                  frequency={selectedCustomerCat2}
                                  setFrequency={handleCategory2}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Customer Category 3'}
                                  dropDownData={cusCat3Suggessions}
                                  frequency={selectedCustomerCat3}
                                  setFrequency={handleCategory3}
                                />
                              </GridItem>

                              {/* <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Department'}
                                  dropDownData={departmentArray}
                                  frequency={selectedDepartment}
                                  setFrequency={handleDepartment}
                                />
                              </GridItem> */}

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Expenses'}
                                  dropDownData={expencesArray}
                                  frequency={selectedExpenses}
                                  setFrequency={handleExpenses}
                                />
                              </GridItem>

                              <GridItem
                                style={{
                                  marginLeft: '0.0rem',
                                  marginTop: '1rem',
                                }}
                                xs={12}
                                sm={12}
                                md={4}
                              >
                                <DropDownSelection
                                  labelforDropDwon={'Mode of Transport'}
                                  dropDownData={transprtModeArray}
                                  frequency={selectedTransportMode}
                                  setFrequency={handleTransportMode}
                                />
                              </GridItem>
                            </GridContainer>
                          </AccordionDetails>
                        </Accordion>

                        <GridContainer>
                          <GridItem
                            style={{ marginLeft: '0.0rem', marginTop: '1rem' }}
                            xs={12}
                            sm={12}
                            md={4}
                          ></GridItem>
                        </GridContainer>

                        {/* 
                                            {variables ?  
                                               ""
                                            : ''}
                                            

                                            {dataSource ?   
                                               
                                                ''
                                            : ''} */}

                        <GridContainer>
                          {categoryType === 'type1'
                            ? handleCatType1()
                            : categoryType === 'type2'
                            ? handleCatType2()
                            : categoryType === 'type3'
                            ? handleCatType3()
                            : categoryType === 'type4'
                            ? handleCatType4()
                            : console.log('Else')}
                        </GridContainer>
                      </CardBody>
                      <CardFooter>
                        {!isView ? (
                          <Button
                            type="submit"
                            color="primary"
                            autoFocus
                            onClick={TileConfigSubmit}
                          >
                            Save
                          </Button>
                        ) : (
                          ''
                        )}
                      </CardFooter>
                    </form>
                  )}
                />
              </div>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default ReportsAndDashboard;
