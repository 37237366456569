import React, { useContext, useEffect, useState } from 'react'
import TrackableLogo from "assets/img/trackable.png"
import { Box, CircularProgress, Typography } from '@material-ui/core'
import hexToRgba from 'hex-to-rgba'
import { RouteReportContext } from '../..'
import { getUser } from 'services/User/UserService'

const Header = () => {
  const [agent, setAgent] = useState();
  const { technicianId, date } = useContext(RouteReportContext);

  useEffect(() => {
    getUser(technicianId)
    .then(res => {
      const agentObj = res.data;
      if (agentObj) setAgent(`${agentObj.first_name} ${agentObj.last_name}`)
    })
  },[technicianId])

  return (
    <Box boxShadow="0 0 10px 5px rgba(0,0,0,0.25)" height="220px" bgcolor="#efefef" borderRadius="10px">
      <Box p="15px" pt="10px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <img src={TrackableLogo} width="70%" />
      <Typography style={{textTransform:"uppercase", fontWeight:800, color:hexToRgba("#fc4a15", 0.8), fontSize:"12px"}}>
        Field Agent Route Report
        </Typography>
        <Box marginTop="10px" width="100%" display="flex" alignItems="center" flexDirection="column" justifyContent="space-between" style={{gap:"5px"}}>
            <DetailBox label="Agent" detail={agent} />
            <DetailBox label="Date" detail={date} />
        </Box>
      </Box>
    </Box>
  )
}

const DetailBox = ({label, detail}) => (
  <Box bgcolor={hexToRgba("#787878", 0.1)} width="200px" py="3px" px="10px" borderRadius="10px">
    <Typography style={{fontWeight:600, fontSize:"13px"}}>
      <span style={{fontSize:"10px", textTransform:"uppercase"}}>{label}</span>
      <br />
      {detail || "..."}
    </Typography>
  </Box>
)

export default Header
