import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  GoogleMap,
  Marker,
  LoadScript,
  Autocomplete,
  DirectionsService,
  DirectionsRenderer,
  InfoWindow,
  Polyline,
  MarkerClusterer,
} from '@react-google-maps/api';
import Geocode from 'react-geocode';
import classes from './Maps.module.css';
import { GoogleMapsAPI } from './ClientConfig';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import UrlProperties from 'Util/Property/UrlProperties';
import { FaWindowMinimize, FaMapMarkerAlt } from 'react-icons/fa';
import {
  DelayedIcon,
  DelayedPendingIcon,
  IdlingIcon,
  IdlingPendingIcon,
  OnthewayIcon,
  OnthewayPendingIcon,
  OntimeIcon,
  OntimePendingIcon,
} from 'assets/icons/MapMarkers.js';
import { OutletIcon, DistributorIcon } from 'assets/icons/MapMarkers';
import { Checkbox } from '@material-ui/core';

Geocode.setApiKey(GoogleMapsAPI);
Geocode.setRegion('lk');
Geocode.enableDebug();
Geocode.setLanguage('en');

const libraries = ['places'];
const distanceTreshold = 10000;
const colors = [
  '#3733FF',
  '#32AC4A',
  '#923A91',
  '#DB3022',
  '#40A7B1',
  '#EA8F29',
  '#ffffe0',
  '#800080',
  '#ff0000',
  '#ffff00',
  '#e66e2d',
  '#fc65b1',
  '#545366',
];
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const baseUrl = UrlProperties.baseUrl.split(':8089')[0];
const markerList = [
  {
    key: 2,
    icon: DelayedIcon,
    info: 'Delayed',
  },
  {
    key: 1,
    icon: DelayedPendingIcon,
    info: 'Delayed Pending',
  },
  {
    key: 4,
    icon: IdlingIcon,
    info: 'Idling',
  },
  {
    key: 3,
    icon: IdlingPendingIcon,
    info: 'Idling Pending',
  },
  {
    key: 6,
    icon: OnthewayIcon,
    info: 'On-the-way',
  },
  {
    key: 5,
    icon: OnthewayPendingIcon,
    info: 'On-the-way Pending',
  },
  {
    key: 8,
    icon: OntimeIcon,
    info: 'On-time',
  },
  {
    key: 7,
    icon: OntimePendingIcon,
    info: 'On-time Pending',
  },
  // {
  //   key: 8,
  //   icon: OutletIcon,
  //   info: 'Outlet',
  // },
  {
    key: 8,
    icon: OutletIcon,
    info: 'Customers',
  },
];

const Maps = (props) => {
  const [address, setAddress] = useState(props.address);
  const [city, setCity] = useState('');
  const [area, setArea] = useState('');
  const [state, setState] = useState('');
  const [mapPosition, setMapPosition] = useState({
    lat: props.center.lat,
    lng: props.center.lng,
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: props.center.lat,
    lng: props.center.lng,
  });
  const [markers, setMarkers] = useState(props.markers);
  const [marker, setMarker] = useState(props.marker);
  const [renders, setRenders] = useState(null);
  const [directions, setDirections] = useState(props.directions);
  const [directionMarkers, setDirectionMarkers] = useState(null);
  const [update, setUpdate] = useState(false);
  const [directionElements, setDirectionElements] = useState([]);
  const [isMarkerContainerMinimize, setIsMarkerContainerMinimize] = useState(false);
  const [isOpen, setIsOpen] = useState({});
  const [hoveredMarker, setHoveredMarker] = useState(null);
  const [isShowOutletMarkers, setIsShowMarkers] = useState(true);

  const handleShowMarkers = () => setIsShowMarkers(!isShowOutletMarkers);

  const handleMouseOver = (markerId) => {
    setHoveredMarker(markerId);
  };

  const handleMouseOut = () => {
    setHoveredMarker(null);
  };

  const handleToggleOpen = (id) => {
    setIsOpen((prevIsOpen) => ({
      ...prevIsOpen,
      [id]: !prevIsOpen[id],
    }));
  };

  useEffect(() => {
    if (isShowOutletMarkers)
      setMarkers(props.markers)
    else
      setMarkers(props.markers.filter(marker => marker.userType !== "outlet"))
  },[isShowOutletMarkers, props.markers])

  useEffect(()=>{
    let isOpenObj = {}
    props.markers && props.markers.length > 0 && props.markers.forEach((marker) => {
      isOpenObj[marker.id] = false;
    });
    setIsOpen(isOpenObj);
  },[props.markers])

  const autocompleteRef = useRef(null);

  const onLoad = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  useEffect(() => {
    if (address) {
      Geocode.fromAddress(address).then(
        (response) => {
          const address = response.results[0].formatted_address;
          const addressArray = response.results[0].address_components;
          const city = getCity(addressArray);
          const area = getArea(addressArray);
          const state = getState(addressArray);

          const loc = response.results[0].geometry.location;

          let centerLat = markerPosition.lat;
          let centerLong = markerPosition.lng;

          if (!getIsSameLocation(loc, markerPosition)) {
            centerLat = loc.lat;
            centerLong = loc.lng;
            props.placeHandler(centerLat, centerLong);
          }

          setAddress(address || '');
          setArea(area || '');
          setCity(city || '');
          setState(state || '');
          setMarkerPosition({ lat: centerLat, lng: centerLong });
          setMapPosition({ lat: centerLat, lng: centerLong });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      let directEls = [];
      if (props.directions && props.directions.length > 0) {
        directEls = getDirctionQuery();
      }
      // setMarkerPosition({ lat: markerPosition.lat, lng: markerPosition.lng });
      // setMapPosition({ lat: mapPosition.lat, lng: mapPosition.lng });
      setDirectionElements(directEls);
      setUpdate(!update);
    }
  }, [address, props.directions]);

  const getIsSameLocation = (pointA, pointB) => {
    return getDistance(pointA, pointB) < distanceTreshold;
  };

  const getDistance = (pointA, pointB) => {
    const lat1 = pointA.lat;
    const lon1 = pointA.lng;

    const lat2 = pointB.lat;
    const lon2 = pointB.lng;

    const R = 6371e3; // earth radius in meters
    const φ1 = lat1 * (Math.PI / 180);
    const φ2 = lat2 * (Math.PI / 180);
    const Δφ = (lat2 - lat1) * (Math.PI / 180);
    const Δλ = (lon2 - lon1) * (Math.PI / 180);

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    return distance; // in meters
  };

  const getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        'administrative_area_level_2' === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  const getArea = (addressArray) => {
    let area = '';
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            'sublocality_level_1' === addressArray[i].types[j] ||
            'locality' === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  const getState = (addressArray) => {
    let state = '';
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          'administrative_area_level_1' === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === 'address') setAddress(value);
    if (name === 'city') setCity(value);
    if (name === 'area') setArea(value);
    if (name === 'state') setState(value);
  };

  const onMarkerDragEnd = (event) => {
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        const city = getCity(addressArray);
        const area = getArea(addressArray);
        const state = getState(addressArray);

        setAddress(address || '');
        setArea(area || '');
        setCity(city || '');
        setState(state || '');
        setMarkerPosition({ lat: newLat, lng: newLng });
        setMapPosition({ lat: newLat, lng: newLng });

        props.placeHandler(newLat, newLng);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onPlaceSelected = () => {
    if (autocompleteRef.current !== null) {
      const place = autocompleteRef.current.getPlace();
      if (typeof place.address_components === 'undefined') {
        return;
      }
      const address = place.formatted_address;
      const addressArray = place.address_components;
      const city = getCity(addressArray);
      const area = getArea(addressArray);
      const state = getState(addressArray);
      const latValue = place.geometry.location.lat();
      const lngValue = place.geometry.location.lng();

      setAddress(address || '');
      setArea(area || '');
      setCity(city || '');
      setState(state || '');
      setMarkerPosition({ lat: latValue, lng: lngValue });
      setMapPosition({ lat: latValue, lng: lngValue });

      props.placeHandler(latValue, lngValue);
    }
  };

  const rainbow = (step) => {
    if (step >= colors.length) {
      return '#1a3788';
    } else {
      return colors[step];
    }
  };

  const directionsCallback = (response) => {
    if (response !== null) {
      if (response.status === 'OK') {
        const renderDir = (
          <DirectionsRenderer
            key={Math.random()}
            options={{
              directions: response,
              preserveViewport: true,
              suppressMarkers: true,
              polylineOptions: {
                strokeOpacity: 0.7,
                strokeColor: rainbow(response.request.region.split('_')[0]),
                strokeWeight: 4,
              },
            }}
            // onClick={onToggleOpen}
          />
        );

        setRenders((prevRenders) => (prevRenders ? [...prevRenders, renderDir] : [renderDir]));
      } else if (response.status === 'OVER_QUERY_LIMIT') {
        setTimeout(() => {}, 2000);
      } else {
        console.log('response: ', response);
      }
    }
  };

  const getDirctionQuery = useCallback(() => {
    const dirMarkers = [];
    let index = 0;
    const dirArray = [];

    props.directions.map((direction, ind) => {
      const directionDto = direction.journeyDtoList;
      const leng = directionDto.length;
      let originLocation = {
        lat: directionDto[0].startLatitude,
        lng: directionDto[0].startLongitude,
      };

      let detination = leng > 1 ? {
        lat: directionDto[directionDto.length - 2].endLatitude,
        lng: directionDto[directionDto.length - 2].endLongitude,
      } : {
        lat: directionDto[directionDto.length - 1].endLatitude,
        lng: directionDto[directionDto.length - 1].endLongitude,
      };

      const srLabel = `${direction.srName}(${direction.srId})`;

      dirMarkers.push(
        createDirectionMarker(originLocation, 'S', srLabel, alphabet[index])
      );
      index++;
      dirMarkers.push(
        createDirectionMarker(detination, 'F', srLabel, alphabet[index])
      );
      index++;

      const wayPoints = [];
      for (const i in directionDto) {
        const srLabel1 = `${srLabel} - ${directionDto[i].endLatitude} ,${directionDto[i].endLongitude}`;
        if (directionDto[i].endLatitude === null) continue;

        const loc = {
          lat: directionDto[i].endLatitude,
          lng: directionDto[i].endLongitude,
        };
        wayPoints.push(loc);
      }

      detination = {
        lat: directionDto[directionDto.length - 1].endLatitude,
        lng: directionDto[directionDto.length - 1].endLongitude,
      };

      dirArray.push(
        <Polyline
          path={wayPoints}
          options={{
            strokeColor: rainbow(ind),
            strokeWeight: 5,
          }}
          strokeOpacity={0.8}
          strokeWeight={10}
          key={2}
        />
      );
    });

    return dirMarkers.length > 0 ? dirArray.concat(dirMarkers) : dirArray;
  }, [props.directions]);

  const createDirectionMarker = (position, letter, label, markerId) => (
    <Marker
      position={{ lat: position.lat, lng: position.lng }}
      icon={`http://maps.google.com/mapfiles/marker${letter}.png`}
      key={markerId}
      title={label}
    />
  );

  const markerInfoMinimizeHandler = () => {
    setIsMarkerContainerMinimize(!isMarkerContainerMinimize);
  };

  const mapStyles = {
    height: '100vh',
    width: '100%',
  };

  const handleZoomChanged = () => {
    if (mapRef.current) {
      const newCenter = mapRef.current.getCenter(); // Get the current center of the map
      setMapPosition({
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      });
    }
  };

  const mapRef = useRef(null);

  const onLoad2 = (map) => {
    mapRef.current = map;
  };


  return (
    <div className={classes["map-container"]}>
      <LoadScript googleMapsApiKey={GoogleMapsAPI} libraries={libraries}>
        <div
          className={
            !isMarkerContainerMinimize
              ? classes["marker-description-container"]
              : classes["marker-description-container-minimize"]
          }
          onClick={isMarkerContainerMinimize && markerInfoMinimizeHandler}
        >
          <GridContainer style={{ position: "relative" }}>
            {!isMarkerContainerMinimize && (
              <div
                className={classes["minimize-btn"]}
                onClick={markerInfoMinimizeHandler}
              >
                <FaWindowMinimize />
              </div>
            )}
            {!isMarkerContainerMinimize ? (
              markerList.map((marker) => (
                <GridItem key={marker.key} xs={6} sm={6} md={6}>
                  <div className={classes["marker-item"]}>
                    <img
                      className={classes["marker-icon"]}
                      src={marker.icon}
                      alt={marker.info}
                    />
                    <div className={classes["marker-info"]}>{marker.info}</div>
                  </div>
                </GridItem>
              ))
            ) : (
              <div className={classes["marker-icon-btn-container"]}>
                <FaMapMarkerAlt
                  size={20}
                  className={classes["marker-icon-btn"]}
                />
              </div>
            )}
          </GridContainer>
        </div>
        <div style={{
          position:"absolute", 
          top:"15px", 
          left:"15px", 
          padding:"10px", 
          zIndex:10, 
          backgroundColor:"white", 
          fontSize:"12px", 
          fontWeight:700,
          boxShadow:"0 0 10px 3px rgba(0,0,0,0.1)",
          cursor:"pointer",
          }}
          
          onClick={handleShowMarkers}
          >
          <Checkbox checked={isShowOutletMarkers} onChange={handleShowMarkers} style={{color: "#f44336"}}/> SHOW CUSTOMERS
        </div>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={props.zoom}
          center={{
            lat: mapPosition.lat,
            lng: mapPosition.lng,
          }}
          onZoomChanged={handleZoomChanged}
          onLoad={onLoad2}
          options={{ 
            gestureHandling: "cooperative",
            mapTypeControl: false,
          }}
        >
          {markers ? (
            markers.map((marker) =>
              markers.length > 0 ? (
                <Marker
                  position={{
                    lat: marker.markerPosition.lat,
                    lng: marker.markerPosition.lng,
                  }}
                  onMouseOver={() => {
                    handleMouseOver(marker.id);
                  }}
                  onMouseOut={handleMouseOut}
                  onClick={() => {
                    marker.tooltipEnabled &&
                      setMapPosition({
                        lat: marker.markerPosition.lat,
                        lng: marker.markerPosition.lng,
                      });
                    marker.tooltipEnabled && handleToggleOpen(marker.id);
                  }}
                  icon={{
                    url:
                      marker.icon ||
                      "http://maps.google.com/mapfiles/marker.png",
                    anchor: { x: 2, y: 2 },
                    scaledSize: { width: 20, height: 30 },
                    labelOrigin: { x: 15, y: -10 },
                    scale: marker.scale || 2,
                  }}
                  key={marker.id}
                  label={
                    hoveredMarker === marker.id
                      ? {
                          text: marker.label || "",
                          color: "#B22900",
                          fontSize: "16px",
                          fontWeight: "500",
                        }
                      : null
                  }
                >
                  {isOpen[marker.id] && marker.tooltipEnabled && (
                    <InfoWindow
                      onCloseClick={() => handleToggleOpen(marker.id)}
                    >
                      <div
                        style={{
                          maxWidth: 300,
                          padding: 2,
                          backgroundColor: "white",
                          borderRadius: 5,
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                          display: "table",
                          justifyContent: "space-between",
                          alignItems: "center",
                          cursor: "pointer",
                          userSelect: "none",
                          fontFamily: "Arial, sans-serif",
                          fontSize: 14,
                          fontWeight: 500,
                          lineHeight: 1.5,
                          color: "#333",
                          position: "relative",
                          zIndex: 1000,
                        }}
                      >
                        <p>
                          {marker.tooltipProps && (
                            <>
                              {marker.tooltipProps.name && (
                                <div style={{ display: "flex" }}>
                                  <div style={{ fontWeight: 800, margin: 5 }}>
                                    Full Name :{" "}
                                  </div>{" "}
                                  <div style={{ margin: 5 }}>
                                    {" "}
                                    {marker.tooltipProps.name}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ) : (
                marker && (
                  <Marker
                    position={{
                      lat: markerPosition.lat,
                      lng: markerPosition.lng,
                    }}
                    draggable
                    onDragEnd={onMarkerDragEnd}
                    // onClick={handleToggleOpen}
                    icon={{
                      path:
                        marker.icon ||
                        "M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z",
                      scale: marker.scale || 2,
                      strokeWeight: marker.strokeWeight || 0.2,
                      strokeColor: marker.strokeColor || "black",
                      strokeOpacity: marker.strokeOpacity || 1,
                      fillColor: marker.fillColor || "#db140d",
                      fillOpacity: marker.fillOpacity || 0.7,
                    }}
                  >
                    {/* {isOpen && (
                      <InfoWindow onCloseClick={handleToggleOpen}>
                        <div>
                          <p>{marker.info}</p>
                        </div>
                      </InfoWindow>
                    )} */}
                  </Marker>
                )
              )
            )
          ) : (
            <></>
          )}
          {directionElements}
          {renders}
          {directionMarkers}
          <Autocomplete
            onLoad={onLoad}
            onPlaceChanged={onPlaceSelected}
            restrictions={{ country: "lk" }}
          >
            <input
              type="text"
              placeholder="Search places here"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `500px`,
                height: `35px`,
                padding: `5px 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-150px",
                marginTop: "5px",
              }}
            />
          </Autocomplete>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default Maps;
