import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import React from 'react'

const SidebarCheckBox = ({ checked, intermediate, label, onChange, ...rest }) => {
  return (
    <FormGroup style={{position:"relative", left:"10px"}}>
        <FormControlLabel 
            control={
                <Checkbox
                    size="small"
                    style={{
                        color: "rgba(0,0,0,0.8)",
                    }}
                    checked={checked}
                    indeterminate={intermediate}
                    onChange={onChange} />
                } 
            label={
                <span style={{ fontSize: "15px", fontWeight: "700", color: "rgba(0,0,0,0.8)", position:"relative", top:"1px"}}>
                    {label}
                </span>
                }
        />
    </FormGroup>
  )
}

export default SidebarCheckBox
