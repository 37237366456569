import randomColor from "randomcolor";
import chroma from "chroma-js";
import reportService from "services/Reports/ReportsService";

export const fetchJourneyRoute = async (userId, date) => {
    return await new Promise((resolve, reject) => {
        reportService
            .getUserJourneyByDate(userId, date)
            .then(res => {
                const travelRouteList = res.data;
                resolve(finalizeRouteList(travelRouteList));
            })
            .catch(err => {
                reject("Unable to fetch request report", err);
            });
    });
};

const finalizeRouteList = (travelRouteList) => {
    const suggestedColorCodes = [];

    let recursiveCount = 0;

    const generateColor = () => {
        recursiveCount++;
        const color = randomColor({
            luminosity: "dark",
            hue:"random"
        });

        for (const colorCode of suggestedColorCodes) {
            if (recursiveCount > 20) break;
            if (chroma.deltaE(color, colorCode) < 10) return generateColor();
        }
        recursiveCount = 0;
        suggestedColorCodes.push(color);
        return color;
    }

    return travelRouteList.map((travelRoute) => ({
        ...travelRoute, 
        routeColor: (travelRoute.id === -1) ? "#0000ff" : generateColor(),
        isSelected: travelRoute.id === -1
    }))
}