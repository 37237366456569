import axios from '../../axios/axios-default';
import urlProperties from 'Util/Property/UrlProperties';


export const deleteServiceRequestVisitPurpose = async (url, method, data) => {
  let response = {
    success: false,
    isRequestFailed: true,
    status: 500,
  };
  await axios({
    method: method,
    url: url,
    data: data,
  })
    .then(
      (result) => {
        if (result.status == '200') {
          response = {
            success: true,
            status: 200,
            data: 'success',
            isRequestFailed: false,
          };
        } else {
          response = {
            success: false,
            status: result.status,
            data: result.data,
            isRequestFailed: false,
          };
        }
      },
      (err) => {
        console.log('error', err.message);
      }
    )
    .catch((error) => {
      response = {
        success: false,
        data: error.message,
        isRequestFailed: true,
      };
    });
  return response;
};

export const createAndEditServiceRequestVisitPurpose = async (data, isEdit) => {
  let method = 'POST';
  let url = '';
  if (isEdit) {
    method = 'PUT';
    url = urlProperties.serviceRequestVisitPurpose.updateServiceRequestVisitPurpose + data.id;
  } else {
    url = urlProperties.serviceRequestVisitPurpose.createServiceRequestVisitPurpose;
  }
  let result = {
    success: false,
    data: '',
    status: 500,
    isRequestFailed: false,
  };
  await axios({
    method: method,
    url: url,
    data: data,
  })
    .then(
      (response) => {
        if (response.status == 200) {
          result.status = 200;
          return result;
        } else {
          result.success = false;
          result.data = response.data;
          result.status = response.status;
        }
      },
      (err) => {
        result.success = false;
        result.data = err.message;
        result.isRequestFailed = true;
      }
    )
    .catch((err) => {
      result.success = false;
      result.data = err.message;
      result.isRequestFailed = true;
    });
  return result;
};

export const getServiceRequestVisitPurposeCall = async () => {
  let result = {
    success: false,
    data: {},
  };
  await axios
    .get(urlProperties.serviceRequestVisitPurpose.getServiceRequestVisitPurposes)
    .then((response) => {
      result.data = response.data;
      result.success = true;
    })
    .catch((err) => {});
  return result;
};
