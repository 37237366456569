import reportService from "services/Reports/ReportsService";
import { durationCalculator, formatTimeDiff, getFormattedDateTime } from "./datetime-utils";

export const fetchJourneyReport = async(userId, date) => {
  return await new Promise((resolve, reject) => {
      reportService
        .getUserJourneyReportByDate(userId, date)
        .then(res => {
          resolve(finalizeJourneyReport(res.data))
        })
        .catch(err => {
          console.log("Error", err)
          reject("Unable to fetch report", err);
        })
  })
} 
const finalizeJourneyReport = (journeyReportList) => {
  
  const fullJourneyStartTime = journeyReportList[0].journeyStartTime
  const fullJourneyEndTime = journeyReportList[journeyReportList.length - 1].journeyEndTime
  const totalDuration = durationCalculator(fullJourneyStartTime, fullJourneyEndTime);
  const totalJourneyDuration = totalDuration;
  
  let totalJobDuration = 0;
  
  const tableData = journeyReportList.map(journeyReport => {
    const { journeyStartTime: startTimeStamp, 
            journeyEndTime: endTimeStamp, 
            checkIn: checkInTimeStamp, 
            checkOut: checkOutTimeStamp, 
            journeyDuration: journeyDurationMills, 
            jobDuration: jobDurationMills,
            distanceFromDistributor,
            distanceFromPrevJob } = journeyReport;

            
            const { startTime: journeyStartTime, endTime: journeyEndTime } = formatDateTime(startTimeStamp, endTimeStamp);
            const { startTime: checkIn, endTime: checkOut } = formatDateTime(checkInTimeStamp, checkOutTimeStamp);
            const journeyDuration = formatTimeDiff(journeyDurationMills);
            const jobDuration = formatTimeDiff(jobDurationMills);
            
            console.log("Journey Start Time", journeyStartTime)
            console.log("Journey End Time", journeyEndTime)
            
            totalJobDuration += jobDurationMills
            return {
              ...journeyReport,
              journeyStartTime,
              journeyEndTime,
              checkIn,
              checkOut,
              journeyDuration,
              jobDuration,
              distanceFromDistributor: `${distanceFromDistributor.toFixed(2)} KM`,
              distanceFromPreviousJob: `${distanceFromPrevJob.toFixed(2)} KM`
    }
  });

  totalJobDuration = formatTimeDiff(totalJobDuration);
  const totalDistance = tableData[tableData.length - 1].distanceFromDistributor;

  return { tableData, totalJourneyDuration, totalJobDuration, totalDuration, totalDistance };
}


const formatDateTime = (startTimeStamp, endTimeStamp) => {
    const { date: startDate, time: startTime } = getFormattedDateTime(startTimeStamp);
    const { date: endDate, time: endTime } = getFormattedDateTime(endTimeStamp);

    if (startDate === endDate || !(startTimeStamp && endTimeStamp)) return { startTime, endTime };
    else return { startTime: `${startDate} ${startTime}`, endTime: `${endDate} ${endTime}`}
}