import React, { useEffect, useRef, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import generalProperties from '../../../Util/Property/GeneralProperties';
import LoadingOverlay from 'react-loading-overlay';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { handleErrorMessage } from 'services/CommonService';
import DashboardProperties from '../../../Util/Property/DashboardProperties';
import _ from 'lodash';
import { getStatusChartLabel } from '../../../Util/Util';
import {minutesToDaysHoursMinutes , minutesToHoursMinutes} from '../../../Util/Converters/TimeConverters'

const HighlightsChartDetails3 = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bgColor, setBgColor] = useState('red');
  const [chartTitle, setChartTitle] = React.useState('');
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  useEffect(() => {
    // getAllChartDataToDisplay();
    if (props.tab3Data !== null) {
      setTableData(props.tab3Data);
    }
  }, []);

  const getChartTitle = () => {
    try {
      if (
        ('Job Details' === props.action && 'type1' === props.categoryType) ||
        ('Field Expenses' === props.action && 'type1' === props.categoryType)
      ) {
        return (
          props.tab3Data[0].sr_name + ' | ' + props.tab3Data[0].customer_name
        );
      } else if (
        'Staff Activities' === props.action &&
        'type1' === props.categoryType
      ) {
        return props.chartTitle;
      } else if (
        ('Customer Satisfaction' === props.action ||
          'Employee Satisfaction' === props.action) &&
        'type2' === props.categoryType
      ) {
        console.log('================================ ', props);
        return (
          props.tab3Data[0].sr_name + ' | ' + props.tab3Data[0].customer_name
        );
      }
    } catch (e) {}
  };

  const getChartColumns = () => {
    if ('Staff Activities' === props.action && 'type1' === props.categoryType) {
      return [
        {
          title: 'SR Name',
          width: '5%',
          field: 'sr_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Customer',
          width: '5%',
          field: 'customer_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Priority',
          width: '5%',
          field: 'priority',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Province',
          width: '5%',
          field: 'province',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Product',
          field: 'product_name',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Transport',
          width: '5%',
          field: 'transport_medium',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Current Stage',
          field: 'stage',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Started',
          field: 'started_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Completed',
          field: 'completed_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Attend ',
          field: 'updated_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
        },
        {
          title: 'Last Attend By ',
          field: 'updated_by',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Age',
          width: '5%',
          field: 'age',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => minutesToDaysHoursMinutes(rowData['age'].toFixed(0)),

          // render: (rowData) => (
          //   <div
          //     style={{
          //       background:
          //         rowData['age'] !== '-'
          //           ? rowData.age < rowData.kpi
          //             ? '#92D050'
          //             : rowData.age === rowData.kpi
          //             ? '#ffc000'
          //             : ' #ff4747 '
          //           : null,
          //     }}
          //   >
          //     {rowData.age}
          //   </div>
          // ),
        },
        // {
        //   title: 'KPI (Days)',
        //   width: '5%',
        //   field: 'kpi',
        //   headerStyle: {
        //     backgroundColor: '#e6e4df',
        //     padding: '5px',
        //     textAlign: 'center',
        //   },
        //   cellStyle: { textAlign: 'center' },
        // },
      ];
    } else if (
      ('Job Details' === props.action && 'type1' === props.categoryType) ||
      ('Field Expenses' === props.action && 'type1' === props.categoryType)
    ) {
      return [
        {
          title: 'Stage',
          field: 'stage',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Attend By ',
          field: 'attended_by',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Attend Date',
          field: 'attended_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Attend Time ',
          field: 'attended_time',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Milestone Gap',
          width: '15%',
          field: 'gap',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['gap'] !== '-'
                    ? rowData['gap'] < rowData['kpi']
                      ? '#92D050'
                      : rowData['gap'] > rowData['kpi']
                      ? ' #FF3300'
                      : '#FFC000'
                    : null,
              }}
            >
              {minutesToDaysHoursMinutes(rowData['gap']?.toFixed(0))}
              </div>
          ),
        },
        {
          title: 'KPI',
          width: '5%',
          field: 'kpi',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => rowData.kpi.toFixed(0),
        },
      ];
    } else if (
      'Customer Satisfaction' === props.action &&
      'type2' === props.categoryType
    ) {
      //"type2" === props.categoryType
      return [
        {
          title: 'Stage',
          field: 'stage',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Attended By',
          width: '10%',
          field: 'attended_by',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Attended Date',
          field: 'attended_date',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Attended Time',
          field: 'attended_time',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Mile Stone Gap',
          field: 'gap',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['gap'] !== '-'
                    ? rowData['gap'] < rowData['kpi']
                      ? '#92D050'
                      : rowData['gap'] > rowData['kpi']
                      ? ' #FF3300'
                      : '#FFC000'
                    : null,
              }}
            >
              {minutesToDaysHoursMinutes(rowData['gap'])}
              </div>
          ),
        },
        {
          title: 'KPI',
          field: 'kpi',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => rowData.kpi.toFixed(0),
        },
      ];
    }
  };

  return (
    <div>
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ marginTop: '4px' }}>
              <CardBody>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    size="small"
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          {getChartTitle()}
                        </Typography>
                        {/* <Typography variant="body1">More Details</Typography> */}
                      </div>
                    }
                    tableRef={tableRef}
                    columns={getChartColumns()}
                    data={tableData}
                    options={{
                      // filtering: true,
                      sorting: false,
                      search: false,
                      padding: 'dense',
                      border: '1px',
                      maxBodyHeight: '40vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                        lineHeight: 100,
                      },
                    }}
                    onRowClick={null}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default HighlightsChartDetails3;
