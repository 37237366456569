import { Box, darken, Icon, IconButton, MenuItem, Select, styled, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { RouteReportContext } from '.';
import { getFormattedDateTime } from './utils/datetime-utils';

const StyledIconButton = styled(IconButton)(() => ({
    "&.MuiIconButton-root": {
        padding: "5px !important"
    }
}));

const StyledSelect = styled(Select)(() => ({
    backgroundColor:"white",
    borderRadius:"10px",
    fontSize:"13px",
    fontWeight:700,
    "&.MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none", 
        },
        "&:hover fieldset": {
            border: "none", 
        },
        "&.Mui-focused fieldset": {
            border: "none", 
        },
    },
    "& .MuiInputBase-input": { 
        padding: "10px !important", 
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "white",
        borderRadius:"10px !important"
    },
}))

const disabledTime = "00:00 AM"

const TimeStampViewerPanel = ({circleRef, timeBoxRef, lineRef, setSelectedTravelRoute}) => {
    const { travelRoutes, routeMarker, selectedTravelRoute, routeMarkerHandlers } = useContext(RouteReportContext);
    const [selectedTravelRouteList, setSelectedTravelRouteList] = useState([]);
    const [startTime, setStartTime] = useState(disabledTime)
    const [endTime, setEndTime] = useState(disabledTime)

    useEffect(() => {
        if (!selectedTravelRoute) {
            setStartTime(disabledTime)
            setEndTime(disabledTime)
            if (timeBoxRef.current) timeBoxRef.current.innerHTML = disabledTime;

            return;
        };

        const formattedStartTime = getFormattedDateTime(selectedTravelRoute.startTime)?.time
        const formattedEndTime = getFormattedDateTime(selectedTravelRoute?.endTime)?.time
        timeBoxRef.current.innerHTML = formattedStartTime;
        if(formattedStartTime) setStartTime(formattedStartTime)
        if(formattedEndTime) setEndTime(formattedEndTime)
    },[selectedTravelRoute])

    useEffect(() => {
        if (!(travelRoutes && Array.isArray(travelRoutes) && travelRoutes.length > 0)) return;
        setSelectedTravelRouteList(travelRoutes.filter(route => route.isSelected));
    },[travelRoutes])

    useEffect(() => {
        if (selectedTravelRouteList.length === 0) setSelectedTravelRoute(null)
        else if (selectedTravelRouteList.length === 1) setSelectedTravelRoute(selectedTravelRouteList[0])
        else if (selectedTravelRouteList.filter(route => route.id === selectedTravelRoute?.id).length === 0) setSelectedTravelRoute(selectedTravelRouteList[0]);
    },[selectedTravelRouteList]);
    
    const routeColor = selectedTravelRoute?.routeColor;
    const { isPlaying, isDragging, isGpsFocused } = routeMarker;
    const { handlePlay, handleReplay, handleGpsFocus, handleMouseDownOnCircle } = routeMarkerHandlers;

    const handleJourneyChange = (event) => {
        const { value } = event.target;
        setSelectedTravelRoute(selectedTravelRouteList.find(route => route.id == value) || null);
    }

    /* COMMENT: dynamic speed change had to remove due to high volume of markers and 
    if speed change necessary we can achieve it by removing some markers */

  return (
    <Box p="20px" position="fixed" right="15px" top="15px" bottom="15px" width="120px" bgcolor="#efefef" borderRadius="10px" boxShadow="0 0 10px 5px rgba(0,0,0,0.25)" display="flex" flexDirection="column" style={{gap: "20px"}} >
        <Box px="10px" display="flex" flexDirection="column" justifyContent="center" style={{gap:"10px"}}>
            <Typography style={{textAlign:"center", fontWeight:700, fontSize:"16px"}}>Timestamp Viewer</Typography>
            <StyledSelect disabled={selectedTravelRouteList.length === 0} variant='outlined' value={selectedTravelRoute?.id || ''} label="Journey" onChange={handleJourneyChange}>
                {selectedTravelRouteList.map((journey, index) => <MenuItem key={index} value={journey.id}>{journey.name}</MenuItem>)}
            </StyledSelect>
            <Box display="flex" justifyContent="space-between">
                <ViewerIconButton isTravelRoute={!!selectedTravelRoute} icon={isPlaying ? "pause": "play_arrow"} onClick={handlePlay} />
                <ViewerIconButton isTravelRoute={!!selectedTravelRoute} icon="replay" onClick={handleReplay} />
                <ViewerIconButton isTravelRoute={!!selectedTravelRoute} icon={isGpsFocused?"gps_fixed":"gps_not_fixed"} onClick={handleGpsFocus} /> 
            </Box>
        </Box>
        <Box bgcolor="white" borderRadius="10px" height="100%" py="10px" px="20px">
            <MainTimeBox isTravelRoute={!!selectedTravelRoute} time={startTime} />
            <Box height="90%" display="flex" pr="10px" justifyContent="end">
                <Box py="10px">
                    <Box
                        ref={lineRef}
                        sx={{
                            position:"relative",
                            height:"100%",
                            width:"0px",
                            borderRadius:"10px",
                            borderLeft:`2px solid ${routeColor || "lightgrey"}`,
                            borderRight:`2px solid ${routeColor || "lightgrey"}`
                        }}
                    >
                        <div
                            ref={circleRef}
                            style={{
                                position:"absolute",
                                width:"fit-content",
                                right: "-10px",
                                fontSize: "20px",
                                color: isDragging ? darken(routeColor, 0.25) : routeColor || "lightgrey",
                                top: "-10px",
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center"
                            }}
                            >
                            <Typography
                                ref={timeBoxRef}
                                style={{
                                    fontSize:"12px",
                                    fontWeight:700,
                                    width:"60px",
                                    color: selectedTravelRoute ?  "rgba(0,0,0,0.7)" : "lightgray"
                                }}                                
                                >
                            </Typography>
                            <Icon fontSize='inherit' onMouseDown={(e) => selectedTravelRoute && handleMouseDownOnCircle(e)}>circle</Icon>
                        </div>
                    </Box>
                </Box>
            </Box>
            <MainTimeBox isTravelRoute={!!selectedTravelRoute} time={endTime} />
        </Box>
    </Box>
  )
}

const MainTimeBox = ({isTravelRoute, time}) => (
        <Box display="flex" justifyContent="end" alignItems="center" height="5%" color={isTravelRoute ? "rgba(0,0,0,0.7)" : "lightgray"} >
            <Typography style={{fontSize:"14px", fontWeight:800}}>
                {time}
            </Typography>
        </Box>
        );

const ViewerIconButton = ({isTravelRoute, icon, onClick}) => <StyledIconButton disabled={!isTravelRoute} onClick={onClick}><Icon fontSize='small'>{icon}</Icon></StyledIconButton>

export default TimeStampViewerPanel
