import * as XLSX from "xlsx-js-style"

export const downloadAsXLSX = (dataList, fileName) => {
    if (!dataList || !dataList.length) {
        console.error("Invalid dataList");
        return;
    }

    let columnWidths = [];
    let mergeList = [];
    let mergeRange = 0;
    let finalDataList = [];
    let tableHeader = { caputered: false, row: -1 };
    let tableFooter = { row: -1 };

    const listContent = dataList.find(data => Array.isArray(data));
    if (listContent) mergeRange = listContent.length - 1;
    
    dataList.forEach((data, index) => {
        if (typeof data === "string") {
            mergeList.push({
                s: { r: index, c: 0 },
                e: { r: index, c: mergeRange }, 
            });
            finalDataList.push([data]); 
        } else if (Array.isArray(data)) {
            if (!tableHeader.caputered) {
                tableHeader = {row: index, caputered: true};
            }
            tableFooter.row = index

            data.forEach((d, index) => {
                const calculatedWidth = d.length + 2;
                if (!columnWidths[index] || columnWidths[index]?.width < calculatedWidth )
                    columnWidths[index] = { width: calculatedWidth };
            })

            finalDataList.push(data);
        }
    });
    
    downloadExcel(mergeList, finalDataList, fileName, { start: tableHeader.row, end: tableFooter.row }, mergeRange+1, columnWidths);
};

const excelStyles = {
        fill: { patternType: "solid", fgColor: { rgb: "d0ecf4" } },
        alignment: { wrapText: true, vertical: "center", horizontal: "center" },
        font: { bold: true },
        border: {
            top: { style: "thin", color: { rgb: "000000" } },
            bottom: { style: "thin", color: { rgb: "000000" } },
            left: { style: "thin", color: { rgb: "000000" } },
            right: { style: "thin", color: { rgb: "000000" } },
        },
}

const headerStyles = {
    alignment : excelStyles.alignment,
    font: { bold: true, sz: 24}
}

const downloadExcel = (mergeList, dataList, fileName, tableRows, tableWidth, columnWidths) => {
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.aoa_to_sheet(dataList);

    if (mergeList.length > 0) workSheet["!merges"] = mergeList;
    if (columnWidths?.length > 0) workSheet["!cols"] = columnWidths;

    const { start, end } = tableRows;

    const firstCellAddress = XLSX.utils.encode_cell({r: 0, c: 0});
    if (workSheet[firstCellAddress]) workSheet[firstCellAddress].s = headerStyles;

    workSheet["!rows"] = [
        { hpx: 30 },
    ]

    for(let row = start; row <=end; row++) {
        for (let column = 0; column<tableWidth; column++) {
            const cellAddress = XLSX.utils.encode_cell({r: row, c: column} )
            if (!workSheet[cellAddress]) continue;
            workSheet[cellAddress].s = (row === start) ? excelStyles : { border: excelStyles.border, alignment: excelStyles.alignment };
        }
    }

    XLSX.utils.book_append_sheet(workBook, workSheet, "Report");
    XLSX.writeFile(workBook, fileName);
};
