export const serviceRequestSalesTypeProperties = {
    editSalesType: "Edit Sales Type",
    deleteSalesType: "Do you want to delete this request Sales Type?",
    addNewSalesType: "Add New Sales Type",
    message: {
        error: {
          loadingError:'could not load Sales Types',
        },
  },
}

const statusArray = [
  { value: "ACTIVE", displayValue: 'Active' },
  { value: "INACTIVE", displayValue: 'Inactive' }
]

export const getSalesTypeForm = () => {
  return {
    name: {
      elType: 'input',
      label: 'Name',
      inputProps: {
          type: 'text',
          placeholder: 'Name',
          value: '',
          initvalue : ''
      },
      formControlProps: {
          fullWidth: true
      },
      validations :{
          required:true
      },
      isFullWidth :true,
      valid:false,
      touched:false,
      labelProps:{
          required : true,
          error:false,
          errormessage: ''
      }
  },
  status: {
    elType: 'select',
    label: 'Status',
    inputProps: {
      options: statusArray,
      value: 'ACTIVE',
      initvalue: 'ACTIVE'
    },
    formControlProps: {
      fullWidth: true
    },
    isFullWidth :true,
  }
  }
}