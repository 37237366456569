/* eslint-disable react/display-name */
import React, { useEffect, useRef, useState } from 'react';
import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import generalProperties from '../../../Util/Property/GeneralProperties';
import LoadingOverlay from 'react-loading-overlay';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import { handleErrorMessage } from 'services/CommonService';
import DashboardProperties from '../../../Util/Property/DashboardProperties';
import _ from 'lodash';
import { getStatusChartLabel } from '../../../Util/Util';
import Viewmore2 from './Viewmore2';
import Viewmore3 from './Viewmore3';
import {minutesToDaysHoursMinutes , minutesToHoursMinutes} from '../../../Util/Converters/TimeConverters'

const HighlightsChartDetails = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openSecondPreview, setOpenSecondPreview] = useState(false);
  const [openThirdPreview, setOpenThirdPreview] = useState(false);
  const [bgColor, setBgColor] = useState('red');
  const [nextTitle, setNextTitle] = useState('');
  const [tab2, setTab2] = useState([]);
  const [tableData, setTableData] = useState([]);
  const tableRef = useRef(null);
  useEffect(() => {
    if (props.tableData !== null) {
      setTableData(props.tableData);
      getFieldNameByFirstReportType()
    }
  }, []);

  useEffect(() => {});

  const getFieldNameByFirstReportType = () =>{
    if(props.firstReportType==="Product Wise") return "Product"
    if(props.firstReportType==="Employee Wise") return "Employee"
    return "SR Type"
  }

  const getChartColumns = () => {
    if ('Staff Activities' === props.action && 'type1' === props.categoryType) {
      return [
        {
          title: 'Departments ',
          field: 'title',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'Staff',
          field: 'count',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'AVG Time spent per Job',
          field: 'avg',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => minutesToHoursMinutes(rowData.avg),
        },
        // {
        //   title: 'Target (KPI) Min',
        //   field: 'kpi',
        //   headerStyle: {
        //     backgroundColor: '#e6e4df',
        //     padding: '5px',
        //     textAlign: 'center',
        //   },
        //   cellStyle: { textAlign: 'center' },
        // },
      ];
    } else if (
      'Job Details' === props.action &&
      'type1' === props.categoryType
    ) {
      return [
        //test and it worked
        {
          title: getFieldNameByFirstReportType(),
          field: 'title',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'No of Jobs ',
          field: 'count',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'AVG Time',
          field: 'avg',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          //render:(rowData) =><div style={{background:rowData.jobs3<rowData.jobs4?"#92D050":" #ff4747 "}}>{rowData.jobs3}</div>},
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['avg'] !== '-'
                    ? rowData['avg'] < rowData['kpi']
                      ? '#92D050'
                      : rowData['avg'] > rowData['kpi']
                      ? ' #FF3300'
                      : '#FFC000'
                    : null,
              }}
            >
              {rowData['avg'] === '-' ? '-' : minutesToDaysHoursMinutes(rowData['avg'].toFixed(0))}
            </div>
          ),
        },
        {
          title: 'KPI',
          field: 'kpi',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => rowData.kpi.toFixed(0),
        },
      ];
    } else if (
      'Field Expenses' === props.action &&
      'type1' === props.categoryType
    ) {
      return [
        {
          title: getFieldNameByFirstReportType(),
          field: 'title',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'No of Jobs ',
          field: 'count',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'AVG Cost (LKR)',
          field: 'avg',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center', minWidth: '100px' },
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['avg'] !== '-'
                    ? rowData['avg'] < rowData['kpi']
                      ? '#92D050'
                      : rowData['avg'] > rowData['kpi']
                      ? ' #FF3300'
                      : '#FFC000'
                    : null,
              }}
            >
              {rowData.avg.toFixed(2)}
            </div>
          ),
        },
        {
          title: 'Budgeted Cost (LKR)',
          field: 'kpi',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
          render: (rowData) => rowData.kpi.toFixed(2),
        },
      ];
    } else if (
      'Customer Satisfaction' === props.action &&
      'type2' === props.categoryType
    ) {
      //"type2" === props.categoryType
      return [
        {
          title: getFieldNameByFirstReportType(),
          field: 'title',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'No of Jobs',
          field: 'count',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'AVG Rate (%)',
          field: 'avg',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
            minWidth: '100px',
          },
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['avg'] !== '-'
                    ? Number(rowData.avg) > rowData.kpi
                      ? '#ff4747'
                      : rowData.avg === rowData.kpi
                      ? '#ffc000'
                      : ' #92D050 '
                    : null,
              }}
            >
              {rowData.avg}
            </div>
          ),
        },
        {
          title: 'KPI (%)',
          field: 'kpi',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
      ];
    } else if (
      'Employee Satisfaction' === props.action &&
      'type2' === props.categoryType
    ) {
      //"type2" === props.categoryType
      return [
        {
          title: 'SR Type',
          field: 'title',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'No of Jobs',
          field: 'count',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
        {
          title: 'AVG Rate (%)',
          field: 'avg',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
            minWidth: '100px',
          },
          render: (rowData) => (
            <div
              style={{
                background:
                  rowData['avg'] !== '-'
                    ? Number(rowData.avg) > rowData.kpi
                      ? '#ff4747'
                      : rowData.avg === rowData.kpi
                      ? '#ffc000'
                      : ' #92D050 '
                    : null,
              }}
            >
              {rowData.avg}
            </div>
          ),
        },
        {
          title: 'KPI (%)',
          field: 'kpi',
          headerStyle: {
            backgroundColor: '#e6e4df',
            padding: '5px',
            textAlign: 'center',
          },
          cellStyle: { textAlign: 'center' },
        },
      ];
    }
  };

  const chartDetailsClose = () => {
    setOpenSecondPreview(false);
  };

  const handleclickevent = (ev) => {
    if ('Staff Activity' === props.chartType) {
      setNextTitle(ev.title);
    } else {
      setNextTitle(ev.title);
    }

    setTab2(ev.second_table);
  };

  return (
    <div>
      {openSecondPreview === true ? (
        <Viewmore2
          open={openSecondPreview}
          viewMoreClose={chartDetailsClose}
          title={props.chartTitle}
          SubTitle={nextTitle}
          tileBody={props.chartType}
          categoryType={props.categoryType}
          action={props.action}
          tab2Data={tab2}
        />
      ) : (
        ''
      )}

      {/* {openThirdPreview === true ?
            <Viewmore3
            open={openThirdPreview}
            viewMoreClose={fileUploadClose2nd}
            title={"Details..."}
            SubTitle ={"........."}
            />:''
        } */}
      <LoadingOverlay active={isLoading} spinner text="Loading ...">
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ marginTop: '4px' }}>
              <CardBody>
                <div className={'material-grid-wrapper'}>
                  <MaterialTable
                    size="small"
                    title={
                      <div>
                        <span className={'grid-title-icon'}>
                          <ListAltOutlinedIcon />
                        </span>
                        <Typography
                          variant="h6"
                          style={{ fontSize: '1rem', marginTop: '-0.7rem' }}
                        >
                          {props.chartTitle}
                        </Typography>
                        {/* <Typography variant="body1">More Details</Typography> */}
                      </div>
                    }
                    tableRef={tableRef}
                    columns={getChartColumns()}
                    data={tableData}
                    options={{
                      // filtering: true,
                      sorting: false,
                      search: false,
                      padding: 'dense',
                      border: '1px',
                      columnResizable: true,
                      maxBodyHeight: '40vh',
                      headerStyle: {
                        fontSize: '0.7rem',
                        textAlignL: 'center',
                      },
                      rowStyle: {
                        fontSize: '0.6rem',
                        fontWeight: 450,
                        padding: '5rem',
                        lineHeight: 100,
                      },
                    }}
                    onRowClick={(event, rowData) => {
                      if ('1' === props.tileStage) {
                        setOpenSecondPreview(true);
                      }
                      handleclickevent(rowData);
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </LoadingOverlay>
    </div>
  );
};

export default HighlightsChartDetails;
