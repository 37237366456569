/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import People from '@material-ui/icons/People';
// core components/views for Admin layout
import DashboardPage from 'views/Dashboard/Dashboard.js';
import Customers from 'views/Customers/Customers.js';
import Customer from 'views/Customers/Customer/Customer';
import CustomerDetails from 'views/Customers/Customer/CustomerDetails';
import RouterProps from './Util/Property/RouterProperties';
import UserManagement from 'views/Settings/UserMangement/UserManagement';
import Settings from 'views/Settings/Settings';
import ProductAttributes from 'views/Settings/Product/ProductAttributes';
import ServiceAgreements from 'views/Settings/ServiceAgreements/ServiceAgreements';
import ServiceAgreement from 'views/Settings/ServiceAgreements/ServiceAgreement/ServiceAgreement';
import Profile from 'views/Profile/Profile';
import ModifyUser from 'views/Users/User/ModifyUser';
import serviceRequestsSettings from 'views/Settings/ServiceRequests/ServiceRequestsSettings';
import ClaimsSettings from 'views/Settings/Claims/ClaimsSettings';
import Workflows from 'views/Settings/Workflows/Workflows';
import ServiceRequests from 'views/ServiceRequests/ServiceRequests';
import ServiceRequest from 'views/ServiceRequests/ServiceRequest/ServiceRequest';
import Checklist from 'views/Settings/ServiceRequests/CheckList/Checklist';
import PriorityConfiguratoin from 'views/Settings/ServiceRequests/PrioriyConfiguration/PriorityConfiguration';
import Products from 'views/Products/Products';
import ProductDetails from 'views/Products/Product/ProductDetails';
import Product from 'views/Products/Product/Product';
import ExpenseCategoryForm from './views/Settings/Claims/ExpenseCategories/ExpenseCategoryForm/ExpenseCategoryForm';
import Estimations from 'views/Estimations/Estimations';
import Estimaion from 'views/Estimations/Estimation/Estimation';
import ProductServiceAgreement from 'views/Products/ServiceAgreements/ServiceAgreement/ProductServiceAgreement';
import ExpenseRuleForm from './views/Settings/Claims/ExpenseRules/ExpenseRulesform/ExpenseRuleForm';
import Reports from 'views/Reports/Reports';
import Training from 'views/Trainings/Training/Training';
import Trainings from 'views/Trainings/Trainings';
import ClaimsManagement from './views/Claims/ClaimsManagement';
import ClaimSetup from './views/Claims/ExpenseSetup/ClaimSetup';
import CustomerFeedback from 'views/Customers/Customer/Feedback/CustomerFeedback';
import UserTabs from './views/Users/UserTabs';
import ModifyCustomerUser from './views/Users/CustomerUserProfile/ModifyCustomerUser';
import UserComment from './views/Trainings/Training/UserComment';
import FuelRateForm from './views/Settings/Claims/FuelRates/ExpenseCategoryForm/FuelRateForm';
import WareHouse from './views/WareHouse/WareHouse';
import WarehouseEstimation from './views/WareHouse/Estimation/WarehouseEstimation';
import Workflow from './views/Settings/Workflows/Workflow/Workflow';
import Calendar from '@material-ui/icons/CalendarToday';
import ProductServiceAgreements from 'views/Products/ServiceAgreements/ProductServiceAgreements';
import AddServiceAgreement from 'views/Products/ServiceAgreements/AddServiceAgreement/AddServiceAgreement';
import Home from '@material-ui/icons/Home';
import Stage from './views/Settings/Workflows/Workflow/Stages/Stage';
import ResetPassword from './views/Profile/ResetPassword';
import Leave from './views/Leave/Leave';
import KpiAttributes from 'views/Settings/Kpi/Kpi';
import LeaveAttributes from 'views/Settings/Leave/LeaveAttributes';
import ReportsAndDashboards from 'views/Settings/ReportsAndDashboard/ReportsAndDashboards';
import ReportsAndDashboard from 'views/Settings/ReportsAndDashboard/ReportsAndDashboard/ReportsAndDashboard';
import RoutePlanner from 'views/ServiceRequests/RoutePlanner/RoutePlanner';

export const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Home',
    rtlName: 'لوحة القيادة',
    icon: Home,
    component: DashboardPage,
    layout: RouterProps.mainDomain,
    isDefaultPath: true,
  },
  {
    path: '/serviceRequests',
    name: 'Service Requests',
    rtlName: 'لوحة القيادة',
    icon: 'assignment_turned_in',
    component: ServiceRequests,
    layout: RouterProps.mainDomain,
  },
  // {
  //   path: '/routePlanner',
  //   name: 'Route Planner',
  //   rtlName: '',
  //   icon: 'BubbleChart',
  //   component: RoutePlanner,
  //   layout: RouterProps.mainDomain,
  // },
  // {
  //   path: '/productServiceAgreements',
  //   name: 'Service Agreements',
  //   rtlName: 'لوحة القيادة',
  //   icon: 'edit_note',
  //   component: ProductServiceAgreements,
  //   layout: RouterProps.mainDomain,
  // },
  // {
  //   path: '/estimations',
  //   name: 'Estimations',
  //   rtlName: 'لوحة القيادة',
  //   icon: 'receipt_long',
  //   component: Estimations,
  //   layout: RouterProps.mainDomain,
  // },
  {
    path: '/users',
    name: 'Users',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: People,
    component: UserTabs,
    layout: RouterProps.mainDomain,
  },

  {
    path: '/customers',
    name: 'Customers',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: 'assignment_ind',
    component: Customers,
    layout: RouterProps.mainDomain,
  },
  {
    path: '/claims',
    name: 'Claims Management',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: 'account_balance',
    component: ClaimsManagement,
    layout: RouterProps.mainDomain,
    // isDefaultPath : true
  },
  {
    path: '/reports',
    name: 'Reports',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: 'topic',
    component: Reports,
    layout: RouterProps.mainDomain,
    // isDefaultPath : true
  },
  {
    path: '/messages',
    name: 'Messages',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: 'build',
    component: Trainings,
    layout: RouterProps.mainDomain,
  },
  {
    path: '/leave',
    name: 'Leave Management',
    rtlName: 'ملف تعريفي للمستخدم',
    icon: 'timer',
    component: Leave,
    layout: RouterProps.mainDomain,
  },
  {
    path: '/settings',
    name: 'Settings',
    rtlName: 'قائمة الجدول',
    icon: 'settings',
    component: Settings,
    layout: RouterProps.mainDomain,
  },
];

export const allRouts = [
  ...dashboardRoutes,
  {
    path: '/customer-dashboard',
    name: 'Customer Dashboard',
    component: CustomerDetails,
    layout: RouterProps.mainDomain,
  },
  {
    path: '/customers/customerDetails',
    name: 'Customer Details',
    component: CustomerDetails,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/customers/addCustomer',
    name: 'Add Customer',
    component: Customer,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/roleAndPermissions',
    name: 'User Mangement',
    component: UserManagement,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/productAttributes',
    name: 'Product Attributes',
    component: ProductAttributes,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/kpi',
    name: 'KPI',
    component: KpiAttributes,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/leave',
    name: 'Leave',
    component: LeaveAttributes,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/reportsAndDashboards',
    name: 'Reports and Dashboard Configurations',
    component: ReportsAndDashboards,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/reportsAndDashboards/reportsAndDashboard',
    name: 'Report And Dashboard',
    component: ReportsAndDashboard,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/users/user',
    name: 'User',
    component: ModifyUser,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/serviceAgreements',
    name: 'Service Agreements',
    component: ServiceAgreements,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/serviceAgreements/serviceAgreement',
    name: 'Service Agreement',
    component: ServiceAgreement,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/resetPassword',
    name: 'Reset Password',
    component: ResetPassword,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/settings/serviceRequestsSettings',
    name: 'Service Requests Settings',
    component: serviceRequestsSettings,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/claimsSettings',
    name: 'Claims Settings',
    component: ClaimsSettings,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/settings/workflows',
    name: 'Workflows',
    component: Workflows,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/serviceRequests/serviceRequest',
    name: 'Service Request',
    component: ServiceRequest,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/serviceRequestsSettings/checklist',
    name: 'Checklist',
    component: Checklist,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/serviceRequestsSettings/priorityConfiguration',
    name: 'Checklist',
    component: PriorityConfiguratoin,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },

  {
    path: '/customers/customerDetails/products',
    name: 'Products',
    component: Products,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },

  {
    path: '/customers/customerDetails/products/addProduct',
    name: 'Product',
    component: Product,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/customers/customerDetails/products/productDetails',
    name: 'Product Details',
    component: ProductDetails,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/customers/customerDetails/estimations/estimation',
    name: 'Estimation',
    component: Estimaion,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/estimations/estimation',
    name: 'Estimation',
    component: Estimaion,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/claimsSettings/addExpenseCategory',
    name: 'Add Expense Category',
    component: ExpenseCategoryForm,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/settings/claimsSettings/expenseCategoryDetails/:id/:type',
    name: 'Edit Expense Category',
    component: ExpenseCategoryForm,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/customers/customerDetails/serviceRequests/serviceRequest',
    name: 'Service Request',
    component: ServiceRequest,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/customers/customerDetails/products/productDetails/serviceAgreement',
    name: 'Product Service Agreement',
    component: AddServiceAgreement,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path:
      '/customers/customerDetails/products/productDetails/serviceRequests/serviceRequest',
    name: 'Service Request',
    component: ServiceRequest,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path:
      '/customers/customerDetails/products/productDetails/estimations/estimation',
    name: 'Service Request',
    component: Estimaion,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/claimsSettings/expenseRulesDetails/:id/:type',
    name: 'Edit Expense Rule',
    component: ExpenseRuleForm,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/settings/claimsSettings/addExpenseRule',
    name: 'Add Expense Rule',
    component: ExpenseRuleForm,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/messages/training',
    name: 'Add Training',
    component: Training,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/messages/usercomment',
    name: 'User Comment',
    component: UserComment,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/claims/:id/:type',
    name: 'Edit Claim',
    component: ClaimSetup,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/claims/add',
    name: 'Add Claim',
    component: ClaimSetup,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/customers/customerDetails/feedback',
    name: 'Feedbacks',
    component: CustomerFeedback,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/settings/claimsSettings/fuelRateDetails/:id/:type',
    name: 'Edit Fuel Rule',
    component: FuelRateForm,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/settings/claimsSettings/addFuelRate',
    name: 'Add Fuel Rule',
    component: FuelRateForm,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/settings/workflows/workflow',
    name: 'Workflow',
    component: Workflow,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  // {
  //   path: '/productServiceAgreements/serviceAgreement',
  //   name: 'Product Service Agreement',
  //   component: ProductServiceAgreement,
  //   layout: RouterProps.mainDomain,
  //   isSubLink: true,
  // },
  {
    path: '/productServiceAgreements/serviceAgreement',
    name: 'Product Service Agreement',
    component: AddServiceAgreement,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/settings/workflows/stage',
    name: 'Stage',
    component: Stage,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
];
export const customerUserRouts = [
  {
    path: '/customer-dashboard',
    name: 'Customer Dashboard',
    component: CustomerDetails,
    layout: RouterProps.mainDomain,
  },
  {
    path: '/customers/customerDetails',
    name: 'Customer Details',
    component: CustomerDetails,
    layout: RouterProps.mainDomain,
  },
  {
    path: '/customers/customerDetails/products',
    name: 'Products',
    component: Products,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/customers/customerDetails/estimations/estimation',
    name: 'Estimation',
    component: Estimaion,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/customers/customerDetails/serviceRequests/serviceRequest',
    name: 'Estimation',
    component: ServiceRequest,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/customers/customerDetails/products/productDetails/serviceAgreement',
    name: 'Product Service Agreement',
    component: AddServiceAgreement,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path:
      '/customers/customerDetails/products/productDetails/serviceRequests/serviceRequest',
    name: 'Service Request',
    component: ServiceRequest,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path:
      '/customers/customerDetails/products/productDetails/estimations/estimation',
    name: 'Service Request',
    component: Estimaion,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/customers/customerDetails/feedback',
    name: 'Feedbacks',
    component: CustomerFeedback,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/customers/customerDetails/products/productDetails',
    name: 'Product Details',
    component: ProductDetails,
    layout: RouterProps.mainDomain,
    isSubLink: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ModifyCustomerUser,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/resetPassword',
    name: 'Reset Password',
    component: ResetPassword,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/customer-dashboard/serviceRequests/serviceRequest',
    name: 'Service Requests',
    component: ServiceRequests,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/customer-dashboard/serviceRequests/serviceRequest/serviceRequest',
    name: 'Service Request',
    component: ServiceRequest,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/customer-dashboard/products/addProduct',
    name: 'Add Product',
    component: Product,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
  {
    path: '/customer-dashboard/estimation',
    name: 'Estimation',
    component: Estimaion,
    layout: RouterProps.mainDomain,
    isSubLink: true,
    isDefaultPath: true,
  },
];
export const wareHouseRoutes = [
  {
    path: '/warehouse',
    name: 'Warehouse',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    component: WareHouse,
    layout: RouterProps.mainDomain,
    isDefaultPath: true,
  },
  {
    path: '/warehouse/estimation',
    name: 'Warehouse',
    rtlName: 'لوحة القيادة',
    icon: Dashboard,
    component: WarehouseEstimation,
    layout: RouterProps.mainDomain,
    isDefaultPath: true,
  },
];
export const getWareHouseUserDashboard = () => {
  return [
    {
      path: `/warehouse`,
      name: 'Warehouse',
      rtlName: 'ملف تعريفي للمستخدم',
      icon: People,
      component: WareHouse,
      layout: RouterProps.mainDomain,
      isDefaultPath: true,
    },
  ];
};
export const getCustomerUserDashboard = () => {
  const loggedInUserCustomerId = localStorage.getItem('userCustomerId');
  return [
    {
      path: `/customer-dashboard`,
      name: 'Home',
      rtlName: 'ملف تعريفي للمستخدم',
      icon: People,
      component: Customers,
      layout: RouterProps.mainDomain,
      isDefaultPath: true,
    },
  ];
};
