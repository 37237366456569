import hexToRgba from "hex-to-rgba";

export const regularMarker = (fillColor) =>
  `<svg width="683" height="683" viewBox="0 0 683 683" fill="#0000ff" xmlns="http://www.w3.org/2000/svg">
    <path  d="M310.4 23.2C253.467 30.5333 201.067 56.1334 160.533 96.5333C112.8 144.133 85.3333 210.267 85.3333 277.467C85.3333 359.467 130.133 453.333 214.133 547.467C254.133 592.267 331.333 661.333 341.333 661.333C348.933 661.333 399.333 618.4 439.6 577.733C523.467 493.067 576.133 406.8 592 328.133C597.6 299.867 598.667 275.733 595.333 248C582.8 142.267 507.067 55.6 404.667 29.4667C376.267 22.1334 338.267 19.7333 310.4 23.2ZM377.067 88.6667C415.2 96.1334 448.933 114.133 476.667 142C504.667 169.867 522.4 203.2 530.267 242.267C533.467 258.4 533.467 294.667 530.267 311.333C514.8 390.933 454.4 451.333 375.333 466.133C357.467 469.467 322.933 469.6 306.267 466.267C267.467 458.533 233.733 440.533 206 412.667C178 384.8 160.267 351.467 152.4 312.4C149.2 296.133 149.2 258.533 152.4 242.267C165.333 177.467 208.533 124.533 268.533 99.8667C282.267 94.2667 302.267 88.8 316.667 86.8C329.733 84.9333 363.867 86 377.067 88.6667Z" fill="${fillColor}"/>
    <circle cx="341.5" cy="277.5" r="191.5" fill="white"/>
    </svg>`;

  export const gpsMarker = (fillColor) =>
      `<svg width="1000" height="1000" viewBox="0 0 1000 1000" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="500" cy="500" r="500" fill="${hexToRgba(fillColor, 0.2)}"/>
    <circle cx="500" cy="500" r="250" fill="${fillColor}"/>
    </svg>`;

