export const durationCalculator = (startTimeStamp, endTimeStamp, isShortTimeStamp = false) => {
    if (!startTimeStamp || !endTimeStamp) return null;
    return formatTimeDiff(Math.abs(endTimeStamp - startTimeStamp), isShortTimeStamp);
  }
  
  export const formatTimeDiff = (timeDiff, isShortTimeStamp = false) => {
    let timeDiffSec = Math.floor(timeDiff / 1000);
    let timeDiffMin = Math.floor(timeDiffSec / 60);
    let timeDiffHours = Math.floor(timeDiffMin / 60);
    timeDiffMin %= 60;
    timeDiffSec %= 60
  
    if (timeDiffSec > 30) {
      timeDiffMin++;
      if (timeDiffMin === 60) {
        timeDiffMin = 0;
        timeDiffHours++;
      }
    }
    
    let sec = isShortTimeStamp ? "S" : "sec";
    let min = isShortTimeStamp ? "M" : "min";
    let hours = isShortTimeStamp ? "H" : "hours";
  
    if (timeDiffHours === 0 && timeDiffMin === 0) return timeDiffSec+sec;
    else if (timeDiffHours === 0) return timeDiffMin+min;
    else if (timeDiffMin === 0) return timeDiffHours+hours;
    else return `${timeDiffHours+hours} ${timeDiffMin+min}`;
  }
  
  export const getFormattedDateTime = (timeStamp) => {
    if (!timeStamp) return { date:null, time: null };
  
    const monthList = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    const dateTime = new Date(timeStamp);
    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
  
    const date = dateTime.getDate();
    const month = monthList[dateTime.getMonth()];
  
    // Determine AM or PM suffix
    const ampm = hours >= 12 ? "PM" : "AM";
  
    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12;
  
    const formattedTime = `${hours
      .toString()
      .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  
    return {
      date: `${date} ${month}`,
      time: formattedTime,
    };
  };
  